import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { GgNotifyService } from 'src/app/services/GgNotify/gg-notify.service';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { StartupService } from 'src/app/services/startup/startup.service';
import { Config } from '../../app.const';
import * as moment from 'moment';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {
  selectedGender: any = 0;
  pageStaticLang: any;
  page: GgPageService;

  nameSurname: string = null;
  email: string = '';
  birthDate: string = null;
  password: string = null;
  passwordRepeat: string = null;
  marriageDate: string = null;
  country: string = null;
  countryData: Array<any> = [];
  selectedCountry: number = NaN;
  anyCountrySelected: boolean = false;
  cityList: Array<any> = [];
  selectedCity: number = NaN;
  selectedCountryCode: string = null;
  verificated: boolean = false;
  constructor(
    private _modalController: ModalController,
    private _getStaticLang: StartupService,
    private _ggPage: GgPageService,
    private _notify: GgNotifyService,
    private toastController: ToastController,
    private _apiService: ApiService
  ) {
    this.page = this._ggPage;
  }

  ngOnInit() {
    this.getCountries();
    this._getStaticLang
      .GetStartUpLanguage(Config.PageNames.RegisterPage)
      .subscribe((staticLangs) => {
        this._notify.hide();
        this.pageStaticLang = staticLangs.languageContent;
      });
  }

  getCountries() {
    this._apiService.get(Config.Api.Countries).subscribe((resp) => {
      this.countryData = resp;
    });
  }

  countryChange(id) {
    if (!Number.isNaN(this.selectedCountry)) {
      this.countryData.forEach((element) => {
        if (element.id == this.selectedCountry) {
          this.selectedCountryCode = element.countryCode;
        }
      });
      this.anyCountrySelected = true;
      // if (this.cityList.length > 0) {
      //   this.cityList = [];
      // }
      // this.getCity(this.selectedCountry);
    }
  }

  getCity(id) {
    if (!Number.isNaN(id)) {
      this._notify.show().then(() => {
        this._apiService
          .get(Config.Api.Countries + '/' + id + '/cities')
          .subscribe((resp) => {
            this.cityList = resp;
            this._notify.hide();
          });
      });
    }
  }

  dissmiss() {
    this._modalController.dismiss({});
  }

  sendVerification() {
    this._apiService
      .get('v1/Account/email/' + this.email + '/' + this.page.shortLangCode)
      .subscribe(
        (resp) => {
          this.openNotify(resp);
        },
        (err) => {
          this._notify.confirm2(
            this.pageStaticLang[this.page.shortLangCode].errorWhenCodeSending,
            '',
            this.pageStaticLang[this.page.shortLangCode].Ok,
            () => {}
          );
        }
      );
  }

  openNotify(verifyCode) {
    this._notify.customInputAlert(
      this.pageStaticLang[this.page.shortLangCode].mailSendedTitle,
      '',
      this.pageStaticLang[this.page.shortLangCode].Cancel,
      () => {},
      this.pageStaticLang[this.page.shortLangCode].approve,
      (data) => {
        if (data.mailVerifyCode === verifyCode.toString()) {
          this.verificated = true;
          this.register();
        } else {
          this.verificated = false;
          this._notify.confirm(
            '',
            this.pageStaticLang[this.page.shortLangCode].codesNotMatched,
            this.pageStaticLang[this.page.shortLangCode].no,
            () => {},
            this.pageStaticLang[this.page.shortLangCode].yes,
            () => {
              this.openNotify(verifyCode);
            }
          );
        }
      },
      [
        {
          id: 'mailVerifyCode',
          name: 'mailVerifyCode',
          placeholder:
            this.pageStaticLang[this.page.shortLangCode]
              .mailVerificationCodePlaceholder,
          type: 'text',
        },
      ]
    );
  }

  register() {
    this._notify.show().then(() => {
      this._apiService
        .get(Config.Api.MobexCustomers_checkEmail + '/' + this.email)
        .subscribe((resp) => {
          if (!resp.data) {
            if (this.checkInput()) {
              if (!this.verificated) {
                this._notify.hide();
                this.sendVerification();
              } else {
                let ddata = {
                  fullName: this.nameSurname,
                  country: this.selectedCountryCode,
                  sex: parseInt(this.selectedGender),
                  birthDate:
                    this.birthDate != null && this.birthDate != undefined
                      ? moment(this.birthDate, 'YYYY-MM-DD').format(
                          'YYYY-MM-DDThh:mm:ss'
                        )
                      : null,
                  email: this.email,
                  password: this.password,
                  mariageDate:
                    this.marriageDate != null && this.marriageDate != undefined
                      ? moment(this.marriageDate, 'YYYY-MM-DD').format(
                          'YYYY-MM-DDThh:mm:ss'
                        )
                      : null,
                  checkIn: moment()
                    .add(-1, 'day')
                    .format('YYYY-MM-DDThh:mm:ss'),
                  checkOut: moment()
                    .add(100, 'years')
                    .format('YYYY-MM-DDThh:mm:ss'),
                  roomNo: '0',
                  pax: 1,
                  paidChildren: 0,
                  freeChildren: 0,
                  baby: 0,
                };
                this._apiService
                  .post(Config.Api.MobexCustomers, ddata)
                  .subscribe((response) => {
                    this._modalController.dismiss();
                  });
              }
            }
          } else {
            this._notify.hide();
            this._notify.confirm2(
              '',
              this.pageStaticLang[this.page.shortLangCode].emailCheck,
              this.pageStaticLang[this.page.shortLangCode].Ok,
              () => {}
            );
          }
        });
    });
  }

  genderChange(event) {
    this.selectedGender = event.target.value;
  }

  checkInput(): boolean {
    if (this.password == this.passwordRepeat) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email)) {
        if (
          (this.nameSurname !== undefined &&
            this.nameSurname !== null &&
            this.email !== undefined &&
            this.email !== null) ||
          (this.password !== undefined && this.password !== null)
        ) {
          return true;
        } else {
          this.presentToast(
            this.pageStaticLang[this.page.shortLangCode].checkRequiredFields
          );
          return false;
        }
      } else {
        this.presentToast(
          this.pageStaticLang[this.page.shortLangCode].emailDoesNotCorrect
        );
        return false;
      }
    } else {
      this.presentToast(
        this.pageStaticLang[this.page.shortLangCode].passwordDoesNotMatch
      );
      return false;
    }
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
      color: 'danger',
      position: 'top',
    });
    toast.present();
  }
}
