import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from 'src/app/app.const';

@Injectable({
  providedIn: 'root'
})
export class GetMenuListService {

  constructor(
    private api: ApiService,
  ) { }

  GetMenuList() {
    return this.api.get(Config.Api.MobileMenus)
  }
}
