import { Component, OnInit, ViewChild } from '@angular/core';
import { IonItemSliding, IonList, ModalController } from '@ionic/angular';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { GgNotifyService } from 'src/app/services/GgNotify/gg-notify.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { GgEventService } from 'src/app/services/GgEvent/gg-event.service';
import { Config } from 'src/app/app.const';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Order } from 'src/app/interfaces/Order';
import { ParseError } from '@angular/compiler';
import * as moment from 'moment';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';//ngClass,ngStyle
import { FormsModule } from '@angular/forms';//ngModel
import { ComponentsModule } from 'src/app/components/components.module';//header
import { PipesModule } from 'src/app/pipes/pipes.module';//dateformat

@Component({
  selector: 'app-basket',
  templateUrl: './basket.page.html',
  styleUrls: ['./basket.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, PipesModule],
})
export class BasketPage implements OnInit {
  data: any = [];
  prices = [];
  piece;
  page: GgPageService;
  basketPageLangContent: any;
  currency = '';
  buttonCurrenct;
  //userData: any;

  @ViewChild('ionSPSliding') ionSPSliding: IonItemSliding;
  constructor(
    private _modalController: ModalController,
    private _page: GgPageService,
    private _events: GgEventService,
    private _storage: GgStorageService,
    private _shoppingCartService: ShoppingCartService,
    private _apiService: ApiService,
    private _notify: GgNotifyService
  ) {
    this.page = this._page;
    this._events.subscribe(Config.Keys.shoppingCart_refresh, (data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this._apiService.get(`${Config.Api.Baskets}`).subscribe(
      (response) => {
        this.basketPageLangContent = response.pageLanguageContent;
        this.piece = this.basketPageLangContent[this.page.shortLangCode].Piece;
      },
      (error) => {
        console.log(error);
      }
    );

    this._shoppingCartService.basketList().then(
      (list: any) => {
        this.data = list;
        if (list.length > 0) {
          this.openCloseStartFunction();
        }

        for (let i = 0; i < this.data.length; i++)
          this.prices[i] = this.data[i].pax * this.data[i].price;
        if (this.data.length > 0) this.currency = this.getCurrency(0);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submitBasket() {
    //if (this.getTotalPrice() != 0) {
    if (this.page.isSignIn(null)) {
      let ddata: Array<Order> = [];
      for (let i = 0; i < this.data.length; i++) {
        let order = {
          hotelReservationId: this.page.userData.hotelId,
          customerId: this.page.userData.mobexCustomersId,
          birthDate: this.page.userData.birthDate + '',
          roomNo: this.page.userData.roomNo,
          orderId: this.data[i].id,
          orderName: this.data[i].languageContent['tr'].title, //this.page.shortLangCode
          pax: this.data[i].pax,
          point: this.data[i].point,
          pointType: this.data[i].pointType,
          price: this.data[i].price,
          useDate: moment(this.data[i].useDate).format('YYYY-MM-DD HH:mm:ss'), //this.data[i].useDate,
          priceCurrencyTypeId: this.data[i].currencyTypeId,
          secondOwnerId:
            this.data[i].secondOwnerId != undefined &&
              this.data[i].secondOwnerId != null
              ? this.data[i].secondOwnerId
              : 0,
          customerName: this.page.userData.fullName,
          customerEmail: this.page.userData.email,
          customerPhone: this.page.userData.phone,
          description: this.data[i].note, //this.data[i].description == null || this.data[i].description == undefined ? '' : this.data[i].description,
          //note: this.data[i].note
        };
        ddata.push(order);
        if (this.data.length - 1 == i) {
          this._apiService
            .post(Config.Api.MobexOrders_PaidService, ddata, null, true)
            .subscribe(
              (response) => {
                this.callService(
                  ddata,
                  this.data[0].sednaContent,
                  this.page.userData
                );
              },
              (error) => {
                this._notify.simpleAlertShow(
                  null,
                  error.error[this.page.shortLangCode],
                  this.basketPageLangContent[this.page.shortLangCode].Ok
                );
              }
            );
        }
      }
    }
  }
  //{"TraceType":null,"TraceCode":"FB00","RoleCode":"YI02","RoleId":null,"TraceSubType":null}

  callService(sendingObjects: Array<any>, sednaContent, userData) {
    this._notify.show().then(() => {
      this._storage.get(Config.Keys.SednaSettings).then((sednaSettings) => {
        if (
          sednaContent != undefined &&
          sednaSettings.isActive &&
          sednaContent.traceCode !== null
        ) {
          if (sednaSettings.obje == 'sedna') {
            var sednaSettingsObject = sednaSettings[sednaSettings.Obje];
            this.SednaLogin(sednaSettingsObject.Token, {
              grant_type: sednaSettingsObject.grant_type,
              client_id: sednaSettingsObject.client_id,
              client_secret: sednaSettingsObject.client_secret,
              scope: sednaSettingsObject.scope,
              apiKey: sednaSettingsObject.apiKey,
            }).subscribe((sednaLoginResp: any) => {
              for (let i = 0; i < sendingObjects.length; i++) {
                let ddata = {
                  TraceDate: moment().format('YYYY-MM-DD'),
                  TraceTime: moment().format('HH:mm'),
                  Note: sendingObjects[i].note,
                  Room: userData.roomNo,
                  GuestId: userData.hotelReservationId,
                  TraceTab: 1,
                  TraceUser: 'Mobile',
                  Importance: 1,
                  Status: 0,
                  TraceType: sednaContent.traceType,
                  TraceCode: sednaContent.traceCode,
                  RoleCode: sednaContent.roleCode,
                  RoleId: sednaContent.roleId,
                  TaskSubType: sednaContent.traceSubType,
                };
                this.SednaTraceOpen(
                  sednaSettingsObject.SetTrace,
                  ddata,
                  sednaLoginResp.access_token
                ).subscribe((traceResponse) => {
                  this._notify.hide();

                  this.data = [];
                  this._notify.simpleAlertShow(
                    '',
                    `${this.basketPageLangContent[this.page.shortLangCode]
                      .OrderReceived
                    }`,
                    `${this.basketPageLangContent[this.page.shortLangCode].Ok}`
                  );
                  this._shoppingCartService.deleteAll();
                  this.close();
                });
              }
            });
          } else if (sednaSettings.obje == 'opex') {
            let opexObject = sednaSettings['opex'];
            var note = '';
            for (let i = 0; i < sendingObjects.length; i++) {
              note =
                note +
                sendingObjects[i].pax +
                ' x ' +
                sendingObjects[i].orderName +
                ' - ' +
                sendingObjects[i].description +
                ' - ' +
                moment(sendingObjects[i].useDate, 'YYYY-MM-DD HH:mm').format(
                  'DD.MM.YYYY - HH:mm'
                ) +
                '\n';
            }
            this._storage
              .get(Config.Keys.globalSettings)
              .then((globSettings) => {
                this._apiService
                  .postGetway(globSettings.opexURL, 'v1/MobilToken', {
                    apiId: Config.Api.apiId,
                    apiKey: Config.Api.apiKey,
                  })
                  .subscribe(
                    (token: any) => {
                      this._apiService
                        .postGetway(
                          globSettings.opexURL,
                          'v1/CagriMisafir',
                          {
                            customerID: userData.hotelReservationId,
                            departman: sednaContent.traceCode,
                            cagrikod: sednaContent.roleCode,
                            notes: note,
                          },
                          {
                            userToken: token.userToken,
                            uID: token.uID,
                          }
                        )
                        .subscribe(
                          (statusResp) => {
                            this._notify.hide();
                            this.data = [];
                            this._notify.simpleAlertShow(
                              '',
                              `${this.basketPageLangContent[
                                this.page.shortLangCode
                              ].OrderReceived
                              }`,
                              `${this.basketPageLangContent[
                                this.page.shortLangCode
                              ].Ok
                              }`
                            );
                            this._shoppingCartService.deleteAll();
                            this.close();
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              });
          } else {
            this._notify.hide();
            this.data = [];
            this._notify.simpleAlertShow(
              '',
              `${this.basketPageLangContent[this.page.shortLangCode]
                .OrderReceived
              }`,
              `${this.basketPageLangContent[this.page.shortLangCode].Ok}`
            );
            this._shoppingCartService.deleteAll();
            this.close();
          }
        } else {
          this._notify.hide();
          this.data = [];
          this._notify.simpleAlertShow(
            '',
            `${this.basketPageLangContent[this.page.shortLangCode].OrderReceived
            }`,
            `${this.basketPageLangContent[this.page.shortLangCode].Ok}`
          );
          this._shoppingCartService.deleteAll();
          this.close();
        }
      });
    });
  }

  SednaLogin(url, LoginData) {
    return this._apiService.postForSedna(url, LoginData, null, null);
  }

  SednaTraceOpen(url, data, token) {
    return this._apiService.postForSedna(url, data, null, token);
  }

  getCurrency(index) {
    let currencyType = this.data[index].currencyTypeId;
    let currencys = ['₺', '₽', '€', '$', '£', 'Kč', 'PP'];
    return currencys[currencyType];
  }

  getTotalPrice() {
    var totalPrice = 0;
    if (this.data.length <= 0) return totalPrice;
    for (var i = 0; i < this.data.length; i++)
      totalPrice += this.data[i].price * this.data[i].pax;
    return totalPrice;
  }

  remove(_index) {
    let item = this.data[_index];
    this._notify.confirm(
      '',
      `${this.basketPageLangContent[this.page.shortLangCode].AreYouSure} `,
      `${this.basketPageLangContent[this.page.shortLangCode].Cancel}`,
      () => { },
      `${this.basketPageLangContent[this.page.shortLangCode].Ok}`,
      () => {
        this._shoppingCartService.delete(this.data[_index], _index).then(() => {
          this._shoppingCartService.basketList().then((list: any) => {
            if (list.length == 0) {
              this.close();
            }
          });
        });
      }
    );
  }

  increase(_index) {
    this.data[_index].pax += 1;
    this.updateLocalData();
  }

  decrease(_index) {
    if (this.data[_index].pax > 1) this.data[_index].pax -= 1;
    this.updateLocalData();
  }

  updateLocalData() {
    this._storage.set(Config.Keys.shoppingCart, this.data);
    this._events.publish(Config.Keys.shoppingCart_refresh, this.data);
  }

  close() {
    this._modalController.dismiss();
  }

  openCloseStartFunction() {
    setTimeout(() => {
      this.ionSPSliding.open('start').then(() => {
        setTimeout(() => {
          this.ionSPSliding.open('end').then(() => {
            setTimeout(() => {
              this.ionSPSliding.closeOpened();
            }, 500);
          });
        }, 500);
      });
    }, 1000);
  }

  returnForContent() {
    if (Config.Api.homepageToolbar) {
      return true;
    } else {
      return false;
    }
  }

  returnForFab() {
    if (Config.Api.homepageToolbar) {
      return '90px';
    } else {
      return '10px';
    }
  }
}
