import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from 'src/app/app.const';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private api: ApiService
  ) { }


  GetActivity(id: number, date: string) {
    return this.api.get(`${Config.Api.MobexActivitys}/${id}/${date}`);
  }

  GetActivityDetail(id: number) {
    return this.api.get(`${Config.Api.MobexActivitys_detail}/${id}`);
  }

  /**
   * 
   * Date Format is YYYY-MM-DDThh:mm:ss.SSSZ
   */
  JoinActivity(lang: string, data: {
    "customerId": string,
    "orderName": string,
    "roomNo": string,
    "orderId": number,
    "useDate": string,
    "price": number,
    "point": number,
    "pointType": boolean,
    "pax": number,
    "birthDate": string,
    "hotelReservationId": number,
    "customerName": string,
    "customerEmail": string,
    "customerPhone": string
  }) {
    return this.api.post(`${Config.Api.MobexOrders_Activitys}`, data);
  }
}
