import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from './../../app.const';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private _api: ApiService) { }

  GetStartUpLanguage(PageName: string) {
    return this._api.get(Config.Api.StaticPageMobileLanguages + '/' + PageName)
  }
}
