import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './services/api/api.service';
import { GgEventService } from './services/GgEvent/gg-event.service';
import { GgNetworkService } from './services/GgNetwork/gg-network.service';
import { GgNotifyService } from './services/GgNotify/gg-notify.service';
import { GgPageService } from './services/ggPage/gg-page.service';
import { GgStorageService } from './services/GgStorage/gg-storage.service';
import { Config } from './app.const';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { PipesModule } from './pipes/pipes.module';
import { OpenPageService } from './services/open-page/open-page.service';
import { ActivityService } from './services/activity/activity.service';
import { ShoppingCartService } from './services/shopping-cart/shopping-cart.service';
import { ComponentsModule } from './components/components.module';
import { HeaderComponent } from './components/header/header.component';
import { IonicRatingModule } from 'ionic4-rating';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { SednaService } from './services/sedna/sedna.service';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { MyServiceComponent } from './components/my-service/my-service.component';
import { ForegroundService } from '@awesome-cordova-plugins/foreground-service/ngx';
import { Market } from '@ionic-native/market/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { StartupService } from './services/startup/startup.service';
import { UnreadCountService } from './services/UnreadCount/unread-count.service';
import { CompCampaignService } from './services/CompCampaign/comp-campaign.service';
import { FreeServiceService } from './services/freeService/free-service.service';
import { GetUserPointService } from './services/GetUserPoint/get-user-point.service';
import { GetUserLastService } from './services/GetUserLast/get-user-last.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

const services = [
  ApiService,
  GgEventService,
  GgNetworkService,
  GgNotifyService,
  GgPageService,
  GgStorageService,
  Network,
  OpenPageService,
  ActivityService,
  ShoppingCartService,
  SednaService,
  BackgroundMode,
  Globalization,
  ScreenOrientation,
  ForegroundService,
  AndroidPermissions,
  Keyboard,
  StartupService,
  UnreadCountService,
  CompCampaignService,
  FreeServiceService,
  GetUserPointService,
  GetUserLastService,
  SocialSharing,
  BarcodeScanner,
  PhotoViewer,
  Camera,
  MobileAccessibility,
  BluetoothSerial,
  OpenNativeSettings,
];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    ComponentsModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    PipesModule,
    IonicRatingModule,
    FormsModule,
    ReactiveFormsModule,
    IonicSelectableModule,
  ],
  exports: [HeaderComponent, MyServiceComponent],
  providers: [
    StatusBar,
    SplashScreen,
    ...services,
    Device,
    Market,
    { provide: 'CONFIG', useValue: Config },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    { provide: LOCALE_ID, useValue: 'tr-TR' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
