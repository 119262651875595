import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormatter',
})
export class MomentFormatterPipe implements PipeTransform {
  transform(value: unknown, ...args: any[]): unknown {
    let comingFormatType = args[0] !== null ? args[0] : moment.defaultFormatUtc;
    let newFormat = args[1];

    return moment(value, comingFormatType).format(newFormat);
  }
}
