import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from './../../app.const';
@Injectable({
  providedIn: 'root'
})
export class CompCampaignService {

  constructor(private _api: ApiService) { }

  GetCompCampaignList(id: number = 0) {
    return this._api.get(Config.Api.MobexCampaigns_thirdParty + '/' + id);
  }

  GetCampaignList(id: number = 0) {
    return this._api.get(Config.Api.MobexCampaigns + '/' + id);
  }

  GetCampaignPopupList(id: number = 0) {
    return this._api.get(Config.Api.MobexCampaigns + '/popup/' + id);
  }
}
