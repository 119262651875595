import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GgNotifyService {
  loading: any = undefined;
  infiniteSpinners: Array<HTMLIonLoadingElement> = [];
  constructor(
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) { }

  async show(text?, duration?) {
    if (this.loading != undefined) {
      this.loading.dismiss();
      this.loading = undefined;
    } else {
      if (text !== null && text !== undefined && text.trim() !== '') {
        let loading = await this.loadingCtrl.create({
          id: 'loading-indicator',
          spinner: 'circles',
          message: text,
          duration:
            duration !== null &&
              duration !== undefined &&
              !Number.isNaN(duration)
              ? duration
              : 4000,
        });
        this.loading = loading;
        loading.present();
      } else {
        let loading = await this.loadingCtrl.create({
          id: 'loading-indicator',
          spinner: 'circles',
          duration: duration ? duration : 8000,
        });
        this.loading = loading;
        loading.present();
      }
    }
  }

  async infiniteShow() {
    const spinner = await this.loadingCtrl.create({
      spinner: 'circles',
      id:
        'infinite-loading-indicator-' + this.infiniteSpinners.length.toString(),
    });

    spinner.present();
    this.infiniteSpinners.push(spinner);
  }

  async alert(params: any) {
    var _params = Object.assign(
      {},
      {
        header: params.title || '',
        message: params.subtitle || '',
        buttons: ['Tamam'],
      },
      params
    );
    let _alert = await this.alertCtrl.create(_params);
    _alert.present();
  }

  infiniteClose() {
    this.loadingCtrl.dismiss(
      null,
      null,
      'infinite-loading-indicator-' +
      (this.infiniteSpinners.length - 1).toString()
    );
  }

  hide() {
    if (this.loading != null && this.loading != undefined) {
      this.loading.dismiss();
      this.loading = undefined;
      setTimeout(() => {
        this.hide();
      }, 500);
    } else {
      this.loading = undefined;
    }
  }

  async presentToast(
    header: string,
    message: string,
    position: 'top' | 'bottom' | 'middle',
    color:
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'success'
      | 'warning'
      | 'danger'
      | 'light'
      | 'medium'
      | 'dark',
    duration: number = 2000
  ) {
    const toast = await this.toastCtrl.create({
      header: header,
      message: message,
      position: position,
      color: color,
      duration: duration,
    });
    toast.present();
  }

  async simpleAlertShow(title: string, message: string, okText: string) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: title,
      message: message,
      buttons: [okText],
    });
    await alert.present();
  }

  async simpleAlertShowWithFun(
    title: string,
    message: string,
    okText: string,
    metot
  ) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: title,
      message: message,
      buttons: [
        {
          text: okText,
          handler: () => {
            return metot();
          },
        },
      ],
    });

    await alert.present();
  }

  async confirm(
    title: string,
    message: string,
    cancelButtonText: string,
    cancelButtonCallback: any,
    successButtonText: string,
    successButtonCallback: any
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: cancelButtonText,
          role: 'cancel',
          handler: () => {
            cancelButtonCallback();
          },
        },
        {
          text: successButtonText,
          handler: () => {
            successButtonCallback();
          },
        },
      ],
    });

    await alert.present();
  }

  async confirm2(
    title: string,
    message: string,
    successButtonText: string,
    successButtonCallback: any
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: successButtonText,
          handler: () => {
            successButtonCallback();
          },
        },
      ],
    });

    await alert.present();
  }

  async confirm3(
    title: string,
    message: string,
    successButtonText: string,
    successButtonCallback: any
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: successButtonText,
          role: 'cancel',
          handler: () => {
            successButtonCallback();
          },
        },
      ],
    });

    await alert.present();
  }

  async texboxAlert(
    title: string,
    message: string,
    cancelButtonText: string,
    cancelButtonCallback: VoidFunction,
    successButtonText: string,
    successButtonCallback: any,
    inputID: string,
    placeholder: string,
    inputName: string
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      inputs: [
        {
          id: inputID,
          type: 'text',
          placeholder: placeholder,
          name: inputName,
        },
      ],
      buttons: [
        {
          text: cancelButtonText,
          role: 'cancel',
          handler: () => {
            cancelButtonCallback();
          },
        },
        {
          text: successButtonText,
          handler: (inputData) => {
            successButtonCallback(inputData);
          },
        },
      ],
    });

    await alert.present();
  }

  async addOrderInputAlert(
    title: string,
    message: string,
    inputID: string,
    placeholder: string,
    inputName: string,
    successButtonText: string,
    successButtonCallback: any
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      inputs: [
        {
          id: inputID,
          type: 'textarea',
          placeholder: placeholder,
          name: inputName,
        },
      ],
      buttons: [
        {
          text: successButtonText,
          handler: (inputData) => {
            successButtonCallback(inputData);
          },
        },
      ],
    });

    await alert.present();
  }

  async customInputAlert(
    title: string,
    message: string,
    cancelButtonText: string,
    cancelButtonCallback: VoidFunction,
    successButtonText: string,
    successButtonCallback: any,
    inputData: Array<AlertInput>,
    customCssClass: string | null = null
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      backdropDismiss: false,
      inputs: inputData,
      cssClass: customCssClass,
      buttons: [
        {
          text: cancelButtonText,
          role: 'cancel',
          handler: () => {
            cancelButtonCallback();
          },
        },
        {
          text: successButtonText,
          handler: (inputData) => {
            successButtonCallback(inputData);
          },
        },
      ],
    });

    await alert.present();
  }

  async customInputAlert2(
    title: string,
    message: string,
    successButtonText: string,
    successButtonCallback: any,
    inputData: Array<AlertInput>,
    customCssClass: string | null = null
  ) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      backdropDismiss: false,
      inputs: inputData,
      cssClass: customCssClass,
      buttons: [
        {
          text: successButtonText,
          handler: (inputData) => {
            successButtonCallback(inputData);
          },
        },
      ],
    });

    await alert.present();
  }
}

export interface AlertInput {
  type?: TextFieldTypes | 'checkbox' | 'radio' | 'textarea';
  name?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  handler?: (input: AlertInput) => void;
  min?: string | number;
  max?: string | number;
  cssClass?: string | string[];
  attributes?: AlertInputAttributes | AlertTextareaAttributes;
  tabindex?: number;
}

interface TextareaHTMLAttributes<T> extends HTMLAttributes<T> {
  autoFocus?: boolean;
  autofocus?: boolean | string;
  cols?: number;
  disabled?: boolean;
  form?: string;
  maxLength?: number;
  maxlength?: number | string;
  minLength?: number;
  minlength?: number | string;
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  readonly?: boolean | string;
  required?: boolean;
  rows?: number;
  value?: string | string[] | number;
  wrap?: string;
}

export interface AlertTextareaAttributes
  extends TextareaHTMLAttributes<HTMLTextAreaElement> { }
export interface AlertInputAttributes
  extends InputHTMLAttributes<HTMLInputElement> { }

interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
  accept?: string;
  allowdirs?: boolean;
  alt?: string;
  autoCapitalize?: any;
  autocapitalize?: any;
  autoComplete?: string;
  autocomplete?: string;
  autoFocus?: boolean;
  autofocus?: boolean | string;
  capture?: string;
  checked?: boolean;
  crossOrigin?: string;
  crossorigin?: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  dirName?: string;
  disabled?: boolean;
  files?: any;
  form?: string;
  formAction?: string;
  formaction?: string;
  formEncType?: string;
  formenctype?: string;
  formMethod?: string;
  formmethod?: string;
  formNoValidate?: boolean;
  formnovalidate?: boolean;
  formTarget?: string;
  formtarget?: string;
  height?: number | string;
  indeterminate?: boolean;
  list?: string;
  max?: number | string;
  maxLength?: number;
  maxlength?: number | string;
  min?: number | string;
  minLength?: number;
  minlength?: number | string;
  multiple?: boolean;
  name?: string;
  pattern?: string;
  placeholder?: string;
  readOnly?: boolean;
  readonly?: boolean | string;
  required?: boolean;
  selectionStart?: number | string;
  selectionEnd?: number | string;
  selectionDirection?: string;
  size?: number;
  src?: string;
  step?: number | string;
  type?: string;
  value?: string | string[] | number;
  valueAsDate?: any;
  valueAsNumber?: any;
  webkitdirectory?: boolean;
  webkitEntries?: any;
  width?: number | string;
}

interface HTMLAttributes<T = HTMLElement> extends DOMAttributes<T> {
  innerHTML?: string;
  accessKey?: string;
  class?:
  | string
  | {
    [className: string]: boolean;
  };
  contentEditable?: boolean | string;
  contenteditable?: boolean | string;
  contextMenu?: string;
  contextmenu?: string;
  dir?: string;
  draggable?: boolean;
  hidden?: boolean;
  id?: string;
  lang?: string;
  spellcheck?: 'true' | 'false' | any;
  style?: {
    [key: string]: string | undefined;
  };
  tabIndex?: number;
  tabindex?: number | string;
  title?: string;
  inputMode?: string;
  inputmode?: string;
  enterKeyHint?: string;
  enterkeyhint?: string;
  is?: string;
  radioGroup?: string;
  radiogroup?: string;
  role?: string;
  about?: string;
  datatype?: string;
  inlist?: any;
  prefix?: string;
  property?: string;
  resource?: string;
  typeof?: string;
  vocab?: string;
  autoCapitalize?: any;
  autocapitalize?: any;
  autoCorrect?: string;
  autocorrect?: string;
  autoSave?: string;
  autosave?: string;
  color?: string;
  itemProp?: string;
  itemprop?: string;
  itemScope?: boolean;
  itemscope?: boolean;
  itemType?: string;
  itemtype?: string;
  itemID?: string;
  itemid?: string;
  itemRef?: string;
  itemref?: string;
  results?: number;
  security?: string;
  unselectable?: boolean;
}

interface DOMAttributes<T = Element> {
  key?: string | number;
  ref?: (elm?: T) => void;
  slot?: string;
  part?: string;
  exportparts?: string;
  onCopy?: (event: ClipboardEvent) => void;
  onCopyCapture?: (event: ClipboardEvent) => void;
  onCut?: (event: ClipboardEvent) => void;
  onCutCapture?: (event: ClipboardEvent) => void;
  onPaste?: (event: ClipboardEvent) => void;
  onPasteCapture?: (event: ClipboardEvent) => void;
  onCompositionEnd?: (event: CompositionEvent) => void;
  onCompositionEndCapture?: (event: CompositionEvent) => void;
  onCompositionStart?: (event: CompositionEvent) => void;
  onCompositionStartCapture?: (event: CompositionEvent) => void;
  onCompositionUpdate?: (event: CompositionEvent) => void;
  onCompositionUpdateCapture?: (event: CompositionEvent) => void;
  onFocus?: (event: FocusEvent) => void;
  onFocusCapture?: (event: FocusEvent) => void;
  onFocusIn?: (event: FocusEvent) => void;
  onFocusInCapture?: (event: FocusEvent) => void;
  onFocusOut?: (event: FocusEvent) => void;
  onFocusOutCapture?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
  onBlurCapture?: (event: FocusEvent) => void;
  onChange?: (event: Event) => void;
  onChangeCapture?: (event: Event) => void;
  onInput?: (event: Event) => void;
  onInputCapture?: (event: Event) => void;
  onReset?: (event: Event) => void;
  onResetCapture?: (event: Event) => void;
  onSubmit?: (event: Event) => void;
  onSubmitCapture?: (event: Event) => void;
  onInvalid?: (event: Event) => void;
  onInvalidCapture?: (event: Event) => void;
  onLoad?: (event: Event) => void;
  onLoadCapture?: (event: Event) => void;
  onError?: (event: Event) => void;
  onErrorCapture?: (event: Event) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyDownCapture?: (event: KeyboardEvent) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onKeyPressCapture?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  onKeyUpCapture?: (event: KeyboardEvent) => void;
  onAuxClick?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
  onClickCapture?: (event: MouseEvent) => void;
  onContextMenu?: (event: MouseEvent) => void;
  onContextMenuCapture?: (event: MouseEvent) => void;
  onDblClick?: (event: MouseEvent) => void;
  onDblClickCapture?: (event: MouseEvent) => void;
  onDrag?: (event: DragEvent) => void;
  onDragCapture?: (event: DragEvent) => void;
  onDragEnd?: (event: DragEvent) => void;
  onDragEndCapture?: (event: DragEvent) => void;
  onDragEnter?: (event: DragEvent) => void;
  onDragEnterCapture?: (event: DragEvent) => void;
  onDragExit?: (event: DragEvent) => void;
  onDragExitCapture?: (event: DragEvent) => void;
  onDragLeave?: (event: DragEvent) => void;
  onDragLeaveCapture?: (event: DragEvent) => void;
  onDragOver?: (event: DragEvent) => void;
  onDragOverCapture?: (event: DragEvent) => void;
  onDragStart?: (event: DragEvent) => void;
  onDragStartCapture?: (event: DragEvent) => void;
  onDrop?: (event: DragEvent) => void;
  onDropCapture?: (event: DragEvent) => void;
  onMouseDown?: (event: MouseEvent) => void;
  onMouseDownCapture?: (event: MouseEvent) => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  onMouseMove?: (event: MouseEvent) => void;
  onMouseMoveCapture?: (event: MouseEvent) => void;
  onMouseOut?: (event: MouseEvent) => void;
  onMouseOutCapture?: (event: MouseEvent) => void;
  onMouseOver?: (event: MouseEvent) => void;
  onMouseOverCapture?: (event: MouseEvent) => void;
  onMouseUp?: (event: MouseEvent) => void;
  onMouseUpCapture?: (event: MouseEvent) => void;
  onTouchCancel?: (event: TouchEvent) => void;
  onTouchCancelCapture?: (event: TouchEvent) => void;
  onTouchEnd?: (event: TouchEvent) => void;
  onTouchEndCapture?: (event: TouchEvent) => void;
  onTouchMove?: (event: TouchEvent) => void;
  onTouchMoveCapture?: (event: TouchEvent) => void;
  onTouchStart?: (event: TouchEvent) => void;
  onTouchStartCapture?: (event: TouchEvent) => void;
  onPointerDown?: (event: PointerEvent) => void;
  onPointerDownCapture?: (event: PointerEvent) => void;
  onPointerMove?: (event: PointerEvent) => void;
  onPointerMoveCapture?: (event: PointerEvent) => void;
  onPointerUp?: (event: PointerEvent) => void;
  onPointerUpCapture?: (event: PointerEvent) => void;
  onPointerCancel?: (event: PointerEvent) => void;
  onPointerCancelCapture?: (event: PointerEvent) => void;
  onPointerEnter?: (event: PointerEvent) => void;
  onPointerEnterCapture?: (event: PointerEvent) => void;
  onPointerLeave?: (event: PointerEvent) => void;
  onPointerLeaveCapture?: (event: PointerEvent) => void;
  onPointerOver?: (event: PointerEvent) => void;
  onPointerOverCapture?: (event: PointerEvent) => void;
  onPointerOut?: (event: PointerEvent) => void;
  onPointerOutCapture?: (event: PointerEvent) => void;
  onGotPointerCapture?: (event: PointerEvent) => void;
  onGotPointerCaptureCapture?: (event: PointerEvent) => void;
  onLostPointerCapture?: (event: PointerEvent) => void;
  onLostPointerCaptureCapture?: (event: PointerEvent) => void;
  onScroll?: (event: UIEvent) => void;
  onScrollCapture?: (event: UIEvent) => void;
  onWheel?: (event: WheelEvent) => void;
  onWheelCapture?: (event: WheelEvent) => void;
  onAnimationStart?: (event: AnimationEvent) => void;
  onAnimationStartCapture?: (event: AnimationEvent) => void;
  onAnimationEnd?: (event: AnimationEvent) => void;
  onAnimationEndCapture?: (event: AnimationEvent) => void;
  onAnimationIteration?: (event: AnimationEvent) => void;
  onAnimationIterationCapture?: (event: AnimationEvent) => void;
  onTransitionEnd?: (event: TransitionEvent) => void;
  onTransitionEndCapture?: (event: TransitionEvent) => void;
}

export type TextFieldTypes =
  | 'date'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'time'
  | 'week'
  | 'month'
  | 'datetime-local';
