import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MyServiceComponent } from './my-service/my-service.component';
import { MyServiceDetailComponent } from './my-service-detail/my-service-detail.component';
import { PopupCampComponent } from './popup-camp/popup-camp.component';

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [HeaderComponent, MyServiceComponent, MyServiceDetailComponent, PopupCampComponent],
  exports: [HeaderComponent, MyServiceComponent, MyServiceDetailComponent, PopupCampComponent],
  providers: []
})
export class ComponentsModule { }