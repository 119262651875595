import { Injectable } from '@angular/core';
import { GgStorageService } from '../GgStorage/gg-storage.service';
import { GgEventService } from '../GgEvent/gg-event.service';
import { GgNotifyService } from '../GgNotify/gg-notify.service';
import { Config } from 'src/app/app.const';
import { GgPageService } from '../ggPage/gg-page.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {


  //TODO LangItem Değişecek
  langItem: any;
  page: GgPageService;

  constructor(private _storage: GgStorageService,
    private _event: GgEventService,
    private _page: GgPageService,
    private _notify: GgNotifyService) {
    this.page = _page;
  }

  basketList() {
    // eger eklenen kartta ki sayi 0 dan buyuk ise sepete ekle
    return new Promise((resolve, reject) => {
      this._storage.get(Config.Keys.shoppingCart).then(
        (basketList) => {
          let basketData;

          if (basketList && basketList !== null && basketList !== undefined)
            basketData = basketList;
          else
            basketData = [];

          resolve(basketData);
        },
        () => {
          reject();
        });
    });
  }

  addBasket(data) {
    this._storage.get(Config.Keys.shoppingCart).then((shopData: Array<any>) => {
      let tempData: Array<any> = [];
      if (shopData == null || undefined)
        tempData.push(data);
      else {
        shopData.push(data);
        tempData = shopData;
      }
      this._storage.set(Config.Keys.shoppingCart, tempData).then(
        () => {
          this._event.publish(Config.Keys.shoppingCart_refresh, tempData);
        }
      );
    });
  }

  delete(item, index) {
    return new Promise((resolve, reject) => {

      this._storage.get(Config.Keys.shoppingCart).then((basketList) => {
        basketList.splice(index, 1);
        this._storage.set(Config.Keys.shoppingCart, basketList);
        this._event.publish(Config.Keys.shoppingCart_refresh, basketList);
        resolve(basketList);
      });

    });
  }

  deleteAll() {
    return new Promise((resolve, reject) => {
      this._storage.set(Config.Keys.shoppingCart, undefined);
      this._event.publish(Config.Keys.shoppingCart_refresh, []);
      resolve(undefined);
    });
  }


  spaBasketList() {
    // eger eklenen kartta ki sayi 0 dan buyuk ise sepete ekle
    return new Promise((resolve, reject) => {
      this._storage.get(Config.Keys.spaShoppingCart).then(
        (basketList) => {
          let basketData;

          if (basketList && basketList !== null && basketList !== undefined)
            basketData = basketList;
          else
            basketData = [];

          resolve(basketData);
        },
        () => {
          reject();
        });
    });
  }

  spaAddBasket(data) {
    this._storage.get(Config.Keys.spaShoppingCart).then((shopData: Array<any>) => {
      if (shopData == null || shopData == undefined) {
        let tempData = [];
        tempData.push(data)
        this._storage.set(Config.Keys.spaShoppingCart, tempData).then(
          () => {
            this._event.publish(Config.Keys.spaShoppingCart_refresh, tempData);
          }
        );
      } else {
        let tempData: Array<any> = [];
        tempData.push(data);
        this._storage.set(Config.Keys.spaShoppingCart, shopData.concat(tempData)).then(
          () => {
            this._event.publish(Config.Keys.spaShoppingCart_refresh, shopData.concat(tempData));
          }
        );
      }
    });
  }

  spaAddBasketArray(data) {
    this._storage.get(Config.Keys.spaShoppingCart).then((spaShopData: Array<any>) => {
      if (spaShopData == null || spaShopData == undefined) {
        this._storage.set(Config.Keys.spaShoppingCart, data).then(() => {
          this._event.publish(Config.Keys.spaShoppingCart_refresh, data);
        })
      } else {
        let ddata = spaShopData.concat(data);
        this._storage.set(Config.Keys.spaShoppingCart, ddata).then(() => {
          this._event.publish(Config.Keys.spaShoppingCart_refresh, ddata);
        })
      }

    });
  }

  spaDelete(item, index) {
    return new Promise((resolve, reject) => {

      this._storage.get(Config.Keys.spaShoppingCart).then((basketList) => {
        basketList.splice(index, 1);
        this._storage.set(Config.Keys.spaShoppingCart, basketList);
        this._event.publish(Config.Keys.spaShoppingCart_refresh, basketList);
        resolve(basketList);
      });

    });
  }

  spaDeleteAll() {
    return new Promise((resolve, reject) => {
      this._storage.set(Config.Keys.spaShoppingCart, undefined);
      this._event.publish(Config.Keys.spaShoppingCart_refresh, []);
      resolve(undefined);
    });
  }

  printerBasketList() {
    return new Promise((resolve, reject) => {
      this._storage.get(Config.Keys.printerShoppingCart).then(
        (printerBasketList) => {
          let printerBasketData;

          if (printerBasketList && printerBasketList !== null && printerBasketList !== undefined)
            printerBasketData = printerBasketList;
          else
            printerBasketData = [];

          resolve(printerBasketData);
        },
        () => {
          reject();
        });
    });
  }


  printerAddBasketArray(data) {
    this._storage.get(Config.Keys.printerShoppingCart).then((printerCartData: Array<any>) => {
      if (printerCartData == null || printerCartData == undefined) {
        this._storage.set(Config.Keys.printerShoppingCart, data).then(() => {
          this._event.publish(Config.Keys.printerShoppingCart_refresh, data);
        })
      } else {
        let ddata = printerCartData.concat(data);
        this._storage.set(Config.Keys.printerShoppingCart, ddata).then(() => {
          this._event.publish(Config.Keys.printerShoppingCart_refresh, ddata);
        })
      }

    });
  }

  printerDelete(item, index) {
    return new Promise((resolve, reject) => {

      this._storage.get(Config.Keys.printerShoppingCart).then((printerList) => {
        printerList.splice(index, 1);
        this._storage.set(Config.Keys.printerShoppingCart, printerList);
        this._event.publish(Config.Keys.printerShoppingCart_refresh, printerList);
        resolve(printerList);
      });

    });
  }

  printerDeleteAll() {
    return new Promise((resolve, reject) => {
      this._storage.set(Config.Keys.printerShoppingCart, undefined);
      this._event.publish(Config.Keys.printerShoppingCart_refresh, []);
      resolve(undefined);
    });
  }
}