import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from './../../app.const';

@Injectable({
  providedIn: 'root'
})
export class UnreadCountService {

  constructor(
    private api: ApiService
  ) { }

  UnreadCount(mobileRegisterId) {
    return this.api.get(Config.Api.MobileNotificationList_UnreadCount + '/' + mobileRegisterId);
  }
}
