import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { GgEventService } from '../GgEvent/gg-event.service';
import { GgStorageService } from '../GgStorage/gg-storage.service';
import { Config } from './../../app.const';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  langID: string = 'tr';
  ApplicationToken: string = null;
  constructor(
    private _events: GgEventService,
    private _storage: GgStorageService,
    private http: HttpClient
  ) {
    /*     this._storage.get(Config.Keys.ActiveHotel).then((active_hotel) => {
          this.ApplicationToken = active_hotel.token;
        }); */

    this._storage.get(Config.Keys.ActiveHotel).then((active_hotel) => {
      if (!active_hotel || !active_hotel.token) {
        // Token yoksa, kullanıcıyı giriş yapmaya yönlendirebilirsiniz veya başka bir işlem yapabilirsiniz
        this.ApplicationToken = Config.Api.OwnerGroupID;
        console.log('==========', this.ApplicationToken)
      } else {
        this.ApplicationToken = active_hotel.token;
      }
    }).catch((error) => {
      console.error('Error while retrieving active hotel:', error);
      // Burada hata durumunda yapılacak işlemleri ekleyebilirsiniz.
    });


    this._storage
      .get('langItemID')
      .then((id) => {
        this.langID = id;
      })
      .catch(() => {
        this.langID = 'tr';
      });

    this._events.subscribe('langItemID:refresh', (data) => {
      this.langID = data;
    });
    this._events.subscribe(Config.Keys.ActiveHotel_Refresh, (active_hotel) => {
      this.ApplicationToken = active_hotel.token;
    });
  }

  get(query, data?: any): Observable<any> {
    let body = Object.assign({}, data);
    return this.http.get(Config.Api.url + '/' + query, {
      params: body,
      headers: new HttpHeaders({
        ApplicationToken: this.ApplicationToken,
        'Content-Type': 'application/json',
        'Access-Controll-Request-Header': 'application/json',
      }),
    });
  }

  getImage(query, data?: any): Observable<any> {
    return this.http.get(Config.Api.url + '/' + query, {
      responseType: 'blob',
      headers: new HttpHeaders({
        ApplicationToken: this.ApplicationToken,
        'Content-Type': 'image/jpeg',
        'Access-Controll-Request-Header': 'image/jpeg',
      }),
    });
  }

  getWithoutToken(query, data?: any, appToken: string = null): Observable<any> {
    let body = Object.assign(data || {});
    //TODO v1 muhabbeti
    // return this.http.get(Config.Api.url + '/' + 'v1' + '/' + query, {
    return this.http.get(Config.Api.url + '/' + query, {
      params: body,
      headers: new HttpHeaders({
        ApplicationToken:
          appToken != null && appToken != undefined ? appToken : 'Mete',
        'Content-Type': 'application/json',
        'Access-Controll-Request-Header': 'application/json',
      }),
    });
  }

  postWithoutToken(
    query,
    data?: any,
    queryparams?: any,
    appToken: string = null
  ) {
    let body = Object.assign({}, data);

    return this.http.post(Config.Api.url + '/' + query, body, {
      params: queryparams,
      headers: new HttpHeaders({
        ApplicationToken:
          appToken != null && appToken != undefined ? appToken : 'Mete',
      }),
    });
  }

  getWithUrl(url, query, data?: any): Observable<any> {
    let body = Object.assign({}, data);

    let myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('ApplicationToken', this.ApplicationToken);

    var myParams = new HttpParams();
    Object.keys(body).map(function (item, index) {
      myParams.set(item, body[item]);
    });

    // let options = new RequestOptions();
    // options.params = myParams;
    // options.headers = myHeaders;

    return this.http.get(url + '/' + 'v1' + '/' + query, {
      params: myParams,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  getWithUrl2(url, query, data?: any): Observable<any> {
    let body = Object.assign({}, data);
    return this.http.get(url + '/' + query, {
      params: body,
    });
  }

  getWithUrl3(url, query, data?: any): Observable<any> {
    let body = Object.assign({}, data);
    return this.http.get(url, {
      params: body,
      headers: new HttpHeaders({
        ApplicationToken: '7DB7CFD1-6EA2-487D-8F0A-A830E31EFF94',
      }),
    });
  }


  getGetway(url, query, data?: any): Observable<any> {
    var prmx = '';
    //let body = Object.assign({ LangID: this.langID || 1 }, data);

    Object.keys(data).map(function (item, index) {
      prmx += item + '=' + data[item] + '%26';
    });

    return this.http.get(Config.Api.url + '/' + Config.Api.getway + '?url=' + url + '/' + query + '?' + prmx, {
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),//Config.Api.apigetwayToken
    });
  }

  getGetwayHand(url, query): Observable<any> {
    return this.http.get(url + '/' + query, {
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),//Config.Api.apigetwayToken
    });
  }

  postGetway(url, query, data?: any, queryparams?: any) {
    var prmx = '';
    let body = Object.assign({}, queryparams);

    Object.keys(body).map(function (item, index) {
      prmx += item + '=' + body[item] + '%26';
    });

    return this.http.post(
      Config.Api.url +
      '/' +
      Config.Api.getway +
      '?url=' +
      url +
      '/' +
      query +
      (queryparams !== undefined && queryparams !== null ? '?' : '') +
      prmx,
      data,
      {
        //params: queryparams,
        headers: new HttpHeaders({
          ApplicationToken: this.ApplicationToken //Config.Api.apigetwayToken,
        }),
      }
    );
  }

  postWithUrl(url, query, data?: any, queryparams?: any) {
    let body = Object.assign({}, data);

    return this.http.post(url + '/' + 'v1' + '/' + query, body, {
      params: queryparams,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  postWithUrl2(url, query, data?: any, queryparams?: any) {
    let body = Object.assign({}, data);

    return this.http.post(url + query, body, {
      responseType: 'text',
    });
  }

  getV2(query, data?: any): Observable<any> {
    let body = Object.assign({}, data);

    let myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('ApplicationToken', this.ApplicationToken);

    // var myParams = new HttpParams();
    // Object.keys(body).map(function (item, index) {
    //   myParams.set(item, body[item]);
    // });

    // let options = new RequestOptions();
    // options.params = myParams;
    // options.headers = myHeaders;

    return this.http.get(Config.Api.url + '/' + 'v2' + '/' + query, {
      params: body,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  post(query, data?: any, queryparams?: any, isArray: boolean = false) {
    if (isArray) {
      var body = data;
    } else {
      var body = Object.assign({}, data);
    }

    return this.http.post(Config.Api.url + '/' + query, body, {
      params: queryparams,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  postForm(query, data?: any) {
    return this.http.post(
      Config.Api.url + '/' + query,
      this.createFormData(data),
      {
        headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
        responseType: 'text',
      }
    );
  }

  postForSedna(url, data?: any, queryparams?: any, token?: string) {
    if (token !== null && token !== undefined) {
      return this.http.post(
        url,
        data,
        //{ traceDTO: data },
        //{ data },
        {
          params: queryparams,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          }),
        }
      );
    } else {
      let body = new URLSearchParams();
      Object.keys(data).forEach((key, idx) => {
        // body[key] = loginData[key];
        body.append(key, data[key]);
      });
      return this.http.post(url, body, {
        params: queryparams,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      });
    }
  }

  postFormData(query, data?: any, headerParameters?: any) {
    let params = Object.assign({}, data);
    let headerParameter = Object.assign(
      {
        'Content-Type': 'multipart/form-data',
        headers: new HttpHeaders({
          ApplicationToken: 'F610E30E-8864-4834-8C2A-AEA5693A7769',
        }),
      },
      headerParameters
    );
    let _headers: any = new HttpHeaders(headerParameter);

    var formData = this.createFormData(params);

    return this.http.post(Config.Api.url + '/' + query, formData, {
      headers: _headers,
    });
  }

  put(query, data?: any, queryparams?: any) {
    let body = Object.assign({}, data);

    return this.http.put(Config.Api.url + '/' + query, body, {
      params: queryparams,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  delete(query, data?: any) {
    let body = Object.assign({}, data);

    return this.http.delete(Config.Api.url + '/' + 'v1' + '/' + query, {
      observe: body,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  createFormData(
    object: Object,
    form?: FormData,
    namespace?: string
  ): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      }
      // else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
      //     createFormData(object[property], formData, formKey);
      // }
      else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  post2(query, data?: any, queryparams?: any) {
    let body = Object.assign({}, data);
    let _queryparams = Object.assign({}, queryparams);

    return this.http.post(Config.Api.url + '/' + 'v1' + '/' + query, body, {
      params: _queryparams,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  postv2(query, data?: any, queryparams?: any) {
    let body = Object.assign({}, data);

    return this.http.post(Config.Api.url + '/' + 'v2' + '/' + query, body, {
      params: queryparams,
      headers: new HttpHeaders({ ApplicationToken: this.ApplicationToken }),
    });
  }

  ApiResponseCheck(response) {
    if (response.StatusCode && response.StatusCode == 404) {
      return false;
    } else {
      return true;
    }
  }

  AssaDeleteRequest(reservationID) {
    return this.http.get(
      'https://destek.goncagrubu.com/api/v1/AssoEndpointDelete?Endpoint=' +
      reservationID,
      {
        headers: new HttpHeaders({
          Token: '345fd552-9241-4e04-88ba-679453a66c09',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }
}
