import { Component, Input, OnInit } from '@angular/core';
import { Config } from 'src/app/app.const';
import { RemainingText } from 'src/app/interfaces/RemainingText';
import { GgEventService } from 'src/app/services/GgEvent/gg-event.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-my-service-detail',
  templateUrl: './my-service-detail.component.html',
  styleUrls: ['./my-service-detail.component.scss'],
})
export class MyServiceDetailComponent implements OnInit {

  @Input() selectedItem: any;
  @Input() listType: any;
  constructor(
    private _utility: UtilityService
  ) {
  }

  rate;

  ngOnInit() {
  }

  onRateChange(event) { }
  sendEvaluation() { }
  clearRate() { }
  hideDetail() {
    this.selectedItem = undefined;
  }

  cancelReservation(event) {
    //TODO burada bir iptal edilecek olan bir hizmetin type'ı belirleniyor
    switch (this.listType) {
      case 'activity':
        break;
      case 'paid-service':
        break;
    }
  }

  clickEmpty(event) {
    event.stopPropagation();
  }
}