import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from '../../app.const';

@Injectable({
  providedIn: 'root'
})
export class GetUserLastService {

  constructor(private _api: ApiService) { }

  GetUserLast(customerID) {
    return this._api.get(Config.Api.MobexOrder_Last + '/' + customerID);
  }
}
