import { HotelConfig } from './interfaces/HotelConfig';

export class Hotels {
  public static GONCAGROUP = new HotelConfig(
    '49855DB1-7E01-4F7A-B34D-28C3AE3A9805'
  ); //Grup Hotel
  public static HHOMS = new HotelConfig('7DB7CFD1-6EA2-487D-8F0A-A830E31EFF94');
  public static GONCA = new HotelConfig('69B50FA3-144A-41E1-8D4E-A46D4FC676F3');
  public static LARA = new HotelConfig('6EED4287-1643-4C43-8617-496A0300F8E0');
  public static ADALYA = new HotelConfig(
    'E607EB29-E907-45E8-9BB6-057429C312BD'
  );
  public static SEGINUS = new HotelConfig(
    '3D23D9D4-E68C-42B9-A667-DA7381F72663'
  );
  public static MOOD = new HotelConfig('6FEE9489-651F-425A-808C-FC6971B37AD1');
  public static RETAJROYAL = new HotelConfig(
    '0356DCE5-534A-496A-B01C-1E757677609D'
  );
  public static DALAMAN = new HotelConfig(
    '3F1FC43D-4D0B-42CC-A56D-D7400BCE505A'
  );
  public static YASMAK = new HotelConfig(
    'ED14FBD7-BBDC-46C1-864A-B00710034CD0'
  );
  public static MEMORIAL = new HotelConfig(
    'C7338BA3-936E-4561-A918-879D7DCB427C'
  );
  public static BANDLIFFE = new HotelConfig(
    'F6900B10-EA03-4D57-BCEB-F6234ADA45A7'
  );
  public static PORTOBELLO = new HotelConfig(
    '8F5CAA60-193E-488C-9BE4-12CA94C2FEBA'
  );
  public static ALBATROS = new HotelConfig(
    '609A609A-54F2-445D-A96C-3FE2FE4F7CAD'
  );
  public static KAYA = new HotelConfig('FA6B7A58-DC3C-4A7B-8A01-8BCBE35D7C08');
  public static SUSESI = new HotelConfig(
    '1B09586D-8074-4286-B30B-424E61D6F571'
  );
  public static ASKA = new HotelConfig('FC70A8CB-4141-417D-AE0B-85403F502819');
  public static MILLENIUM = new HotelConfig(
    '7053990D-6CC0-45E3-AAB2-28BA832DC20C'
  );
  public static CROWNSIDE = new HotelConfig(
    '1B07BDF7-6B6C-499C-A162-32DA1901AC64'
  );
  public static PERYON = new HotelConfig(
    '4A8BF136-B40F-43A7-8C1C-D60692AE7D77'
  );
  public static HEMERA = new HotelConfig(
    '676D8853-60C6-4821-9E11-DA290242F7A6'
  );
  public static ARUM = new HotelConfig('89EEF566-E052-4B7D-BEC4-6D5A89D9FAA3');
  public static KAMELYA = new HotelConfig(
    '345FD552-9241-4E04-88BA-679453A66C09'
  );
  public static BISUITES = new HotelConfig(
    '63F27F77-F702-4A14-9D1F-7C6BD2EC799E'
  );
  public static SUNWING = new HotelConfig(
    '1E5F36D3-9F60-4DC4-9FF0-9C0814472D54'
  );
  public static ALETRIS = new HotelConfig(
    'AD54FBE2-9302-4EDB-89EC-7DF219276524'
  );
  public static DUJAPREMIUM = new HotelConfig(
    'E176AB8B-95B4-446E-B1F6-F040D25AB356'
  );
  public static DUJABODRUM = new HotelConfig(
    '47D301BD-90D7-4458-B8CB-F40428F44270'
  );
  public static DUJADIDIM = new HotelConfig(
    'BCAA1128-90AC-4C41-971F-1E30BDF030C4'
  );
  public static SIDEROYAL = new HotelConfig(
    'A51ED6A7-B433-4F82-8B01-8039647F86DC'
  );
  public static ACANTHUS = new HotelConfig(
    'CEE96BCF-A70D-4489-BA23-069F8511B8A7'
  );
  public static EGEYAKASI = new HotelConfig(
    'FEE82420-8338-409E-8CF1-E3B303B859AE'
  );
  public static MONTEBAIA = new HotelConfig(
    'EA7BF9E9-EC20-429E-9959-E838E29AD625'
  );
  public static DUJAPERSONEL = new HotelConfig(
    '3a9153e7-5954-40d9-9094-45b879a3dfa5'
  );
  public static VONGROUP = new HotelConfig(
    '4640c216-4b75-46c2-a9d7-17f190000616'
  );
  public static BLUEWAVE = new HotelConfig(
    'C30ECE12-57BF-417E-A63C-022A40E7EE05'
  );
  public static LYKIA = new HotelConfig('0b422f3b-9b73-478f-b768-27e31fed53c9');
  public static HEMERAAKADEMI = new HotelConfig(
    '7322940a-0cf9-4a26-b7d5-f2a152f7e96d'
  );
  public static LAGOHOTEL = new HotelConfig(
    '7114c5d8-c3ae-481f-ba3d-cf835916191e'
  );
  public static THEXBELEK = new HotelConfig(
    '437bd2ce-92c0-4724-8e81-5e0ac29be9fb'
  );
  public static ADAMEVE = new HotelConfig(
    '5a7e38fe-4447-4998-80ee-f90dcbf193ae'
  );
  public static SAMARASPA = new HotelConfig(
    'c6d24de5-1c90-4694-bc0b-a8b27dff08c7'
  );
  public static GREENWOOD = new HotelConfig(
    'e2d38cc1-258d-4f8c-8525-b1d8a9c44b45'
  );
  public static CANEMACADEMY = new HotelConfig(
    '5942c3d2-d5fc-48ab-9e15-6dc73c5e9218'
  );
  public static GOIA = new HotelConfig('92279b24-3060-4723-b1e0-0f65e934e6a7');

  public static ROTTA = new HotelConfig('22ca7953-7964-4796-a332-19488f68f494');

  public static ANDA = new HotelConfig('01eb395f-df66-4f10-87a0-775e1dd6b8b2');

  public static CULLINAN = new HotelConfig(
    '2ae1bedf-99a3-4e61-9942-817f565b8e69'
  );

  public static FLYDOVOLENA = new HotelConfig(
    'ed6bff22-3da3-4c84-aa07-c77881373164'
  );

  public static ALHAMBRA = new HotelConfig(
    '38ac9717-3662-4e28-9334-cc0aee2fd663'
  );

  public static DRAGON = new HotelConfig(
    'b2cd0fa6-8d77-4607-8fa6-295a2bae0752'
  );


  //BU ALAN HER UYGULAMA/OTEL İÇİN GÜNCELLENMELİ {}
  public static ACTIVE_HOTEL = { ownerGroupId: '' };
}
