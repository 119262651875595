import { Injectable, Inject } from '@angular/core';
import { GgPageService } from '../ggPage/gg-page.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Config } from 'src/app/app.const';
import { GgStorageService } from '../GgStorage/gg-storage.service';
import { UtilityService } from '../utility/utility.service';
import { GgEventService } from '../GgEvent/gg-event.service';
import { SignInPage } from 'src/app/pages/sign-in/sign-in.page';
import { ModalController } from '@ionic/angular';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { ConfirmSmsPage } from 'src/app/pages/confirm-sms/confirm-sms.page';
import { ApiService } from '../api/api.service';
import { GgNotifyService } from '../GgNotify/gg-notify.service';

@Injectable({
  providedIn: 'root',
})
export class OpenPageService {
  smsConfirmed: boolean = false;
  groupToken: string = "";
  constructor(
    private _page: GgPageService,
    private _router: Router,
    private _storage: GgStorageService,
    private _events: GgEventService,
    private _modalCtrl: ModalController,
    private _shoppingCartService: ShoppingCartService,
    private _modalController: ModalController,
    private _apiService: ApiService,
    private _notify: GgNotifyService
  ) { }

  go(page) {
    this._page.goWithoutTitle(page);
  }
  OpenPage(item) {
    this._storage.set('pageCounter', undefined);
    this._storage.set(Config.Keys.ParentID, undefined);
    this._shoppingCartService.deleteAll();
    this._shoppingCartService.printerDeleteAll();

    this._page.setData(
      Config.Keys.pageTitle,
      item.languageContent[this._page.shortLangCode].title
    );
    this._page.setData('item', item);

    this._storage.set(Config.Keys.pageItem, item);
    this._events.publish(
      Config.Keys.pageTitle,
      item.languageContent[this._page.shortLangCode].title
    );

    if (item.isSettings && item.isSettings.isInfo) {
      this._page.setDataForParentInfo(item);
      this._storage.set(Config.Keys.isInfo, item.isSettings.isInfo).then(() => {
        this._events.publish(Config.Keys.isInfo, item.isSettings.isInfo);
      });
    }
    switch (item.mobilePage) {
      case Config.PageNames.HomePage:
        if (Config.Api.OpenGroupPage) {
          this._storage.removeAll().then(() => {
            this._storage.set(Config.Keys.ActiveHotelSelected, null);
            this.go('group-hotels');
          });
        } else {
          this._router.navigate(['/home']);
        }
        break;
      case Config.PageNames.MobexInfo:
        this.go(`mobex-info/${item.id}`);
        //this.go('mobex-info/0');
        break;
      case Config.PageNames.MobexInfoDetail:
        if (item.isSettings !== null && item.isSettings !== undefined) {
          if (item.isSettings.isLogin == 1) {
            this._storage.get(Config.Keys.userData).then(async (userData) => {
              if (userData != null && userData != undefined) {
                this.go('mobex-info-detail');
              } else {
                const modal = await this._modalCtrl.create({
                  component: SignInPage,
                  cssClass: 'my-custom-class',
                });

                this._events.publish(Config.Keys.modalSignIn_added, modal);
                modal.onDidDismiss().then((result) => {
                  if (result.data.userSign) {
                    this.go('mobex-info-detail');
                  } else {
                    this._router.navigate(['/home']);
                  }
                });

                modal.present();
              }
            });
          } else {
            this.go('mobex-info-detail');
          }
        } else {
          this.go('mobex-info-detail');
        }
        break;
      case Config.PageNames.MobexFreeService:
        //this.go('mobex-free-services/0');
        this.go(`mobex-free-services/${item.parametrId}`);
        break;
      case Config.PageNames.MobexPaidService:
        //this.go('paid-services/0');
        this.go(`paid-services/${item.parametrId}`);
        break;
      case Config.PageNames.MobexAlacarte:
      case Config.PageNames.MobexPavilion:
        this.go('alacarte-pavilion');
        break;
      case Config.PageNames.MobexBarutAlacarte:
        this._storage.set('barut-alacarte', true).then(() => {
          this.go('alacarte-pavilion');
        });
        break;
      case Config.PageNames.MobexBarutPavilion:
        this._storage.set('barut-alacarte', true).then(() => {
          this.go('alacarte-pavilion');
        });
        break;
      case Config.PageNames.FramePage:
        this.FramePageOpen(item);
        break;
      case Config.PageNames.Survex:
        this.SurvexPageOpen(item);
        break;
      case Config.PageNames.SurvexWeb:
        this.SurvexWebPageOpen(item);
        break;
      case Config.PageNames.HotSpot:
        this.HoSpotWebPageOpen(item);
        break;
      case Config.PageNames.WebPage:
        this.WebPageOpen(item);
        break;
      case Config.PageNames.MobexSettingsContact:
        this.go('contact');
        break;
      case Config.PageNames.MobexActivity:
        this._page.setData('MobexActivity', item);
        this._router.navigate([
          `activity/${item.parametrId}/${moment().format('DD.MM.YYYY')}`,
        ]);
        break;
      case Config.PageNames.MobexDictionary:
        this.go('dictionary');
        break;
      case Config.PageNames.MobilProfile:
        if (this._page.isSignIn('profile')) this.go('profile');
        break;
      case Config.PageNames.MobilProfilePerson:
        if (this._page.isSignIn('profile-personel'))
          this.go('profile-personel');
        break;
      case Config.PageNames.MobilRegister:
        this.go('settings');
        break;
      case Config.PageNames.MobexCampaing:
        this._page.setData('compCampStatus', false);
        this.go('campaigns');
        // this.go('campaign-special');
        break;
      case Config.PageNames.MobexNotices:
        this.go('announcements');
        break;
      case Config.PageNames.MobexCompCampaing:
        this._page.setData('compCampStatus', true);
        this.go('campaigns');
        break;
      case Config.PageNames.MobexCompCampaingDetail:
        this._page.setData('compCampStatus', true);
        this.go('campaign-special');
        break;
      case Config.PageNames.MobexCampaingDetail:
        this._page.setData('compCampStatus', false);
        this.go('campaign-special');
        break;
      case Config.PageNames.MobexSpecial:
        this.go('mobex-special');
        break;
      case Config.PageNames.MobexOwner:
        this._storage.removeAll().then(() => {
          localStorage.clear();
          this._storage.set(Config.Keys.ActiveHotelSelected, null);
          location.href = 'index.html';
        });
        break;
      case Config.PageNames.OwnerGroups:
        //????????????????????????????  
        this._storage.get(Config.Keys.globalSettings).then((settings) => {
          if (settings !== null && settings !== undefined) {
            this.groupToken = settings.groupToken;
            this._storage.removeAll().then(() => {
              localStorage.clear();
              this._storage.set(Config.Keys.ActiveHotelSelected, null);
              this._storage.set("groupToken", this.groupToken);
              location.href = 'index.html';
            });
          }
        });
        break;
      case Config.PageNames.MobexInfoSSS:
        this.go('faq');
        break;
      case Config.PageNames.MobexProducts:
        this.go('spa-category');
        break;
      case Config.PageNames.MobexFoodService:
        this._storage.set(Config.Keys.ParentID, item.parametrId);
        //this.go('paid-services-second/0');
        this.go(`paid-services-second/${item.parametrId}`);
        break;
      case Config.PageNames.MobexPrintFoodService:
        //TODO
        this._storage.set(Config.Keys.ParentID, item.parametrId);
        this.go(`paid-services-print/${item.parametrId}`);
        break;
      case Config.PageNames.MobilQrReader:
        this._storage.set(Config.Keys.QRType, item.parametrId).then(() => {
          this.go('qr-scanner');
        });
        break;

      case Config.PageNames.MobexPaidServicePage:
        this._page.setData(
          'item',
          Object.assign({}, item, { isSpecialDetail: true })
        );
        this.go('paid-service-detail');
        break;

      case Config.PageNames.MobexCampaingPage:
        this._page.setData(
          'item',
          Object.assign({}, item, { isSpecialDetail: true })
        );
        this.go('campaign-detail');
        break;

      case Config.PageNames.OpenDoor:
        if (this._page.isSignIn('open-door')) {
          this._storage.get('smsConfirmed').then((smsConfirmed) => {
            if (smsConfirmed == true) {
              this.go('open-door');
            } else {
              this._storage
                .get(Config.Keys.globalSettings)
                .then((globalSet: any) => {
                  this._storage.get(Config.Keys.userData).then((uData: any) => {
                    if (globalSet.sms) {
                      this.openSms(uData);
                    } else {
                      this.go('open-door');
                    }
                  });
                });
            }
          });
        }
        break;

      case Config.PageNames.MobexInfoSlider:
        this.go(`mobex-info-slider/${item.id}`);
        break;
      case Config.PageNames.CustomerBirthday:
        this.go('birth-days');
        break;
    }
  }

  openSms(customerMobilLogin: any) {
    this._apiService
      .get(Config.Api.StaticPageMobileLanguages + '/SmsPage')
      .subscribe((resp) => {
        var smsPageLang = resp.languageContent;
        if (
          customerMobilLogin.phone !== null &&
          customerMobilLogin.phone !== undefined &&
          customerMobilLogin.phone.trim() !== ''
        ) {
          this.smsModal(customerMobilLogin);
        } else {
          this._notify.confirm2(
            null,
            smsPageLang[this._page.shortLangCode].youDontHavePhone,
            'Ok',
            () => {
              // this.close();
            }
          );
        }
      });
  }

  async smsModal(customerMobilLogin) {
    const clockmodal = await this._modalController.create({
      component: ConfirmSmsPage,
      componentProps: customerMobilLogin,
      cssClass: 'special-sms-confirm-modal',
    });

    clockmodal.present();

    clockmodal.onDidDismiss().then((closeData) => {
      if (closeData.role == 'accepted_code') {
        this._storage.set('smsConfirmed', true).then(() => {
          this.go('open-door');
        });
      }
    });
  }

  FramePageOpen(item) {
    if (item.isSettings.isLogin == 1) {
      this._storage.get(Config.Keys.userData).then(async (userData) => {
        if (userData != null && userData != undefined) {
          this._page.openUrlInFrame(item);
        } else {
          const modal = await this._modalCtrl.create({
            component: SignInPage,
            cssClass: 'my-custom-class',
          });

          this._events.publish(Config.Keys.modalSignIn_added, modal);
          modal.onDidDismiss().then((result) => {
            if (result.data.userSign) {
              this._page.openUrlInFrame(item);
            } else {
              this._router.navigate(['/home']);
            }
          });

          modal.present();
        }
      });
    } else {
      this._page.openUrlInFrame(item);
    }
  }

  SurvexPageOpen(item) {
    this._storage.get(Config.Keys.userData).then(async (userData) => {
      if (userData != null && userData != undefined) {
        var anketItem = {
          surveyID: item.parametrId,
          surveyCode:
            item.surveyCode != null && item.surveyCode != undefined
              ? item.surveyCode
              : null,
          code: null,
          guest: { id: userData.hotelReservationId ^ 1001990234 },
          langID: this._page.shortLangCode,
        };
        this._storage.set('AnketHomeData', anketItem).then(() => {
          this.go('anket-home');
        });
      } else {
        const modal = await this._modalCtrl.create({
          component: SignInPage,
          cssClass: 'my-custom-class',
        });

        this._events.publish(Config.Keys.modalSignIn_added, modal);
        modal.onDidDismiss().then((result) => {
          if (result.data.userSign) {
            this.SurvexPageOpen(item);
          } else {
            this._router.navigate(['/home']);
          }
        });

        modal.present();
      }
    });
  }

  SurvexWebPageOpen(item) {
    this._storage.get(Config.Keys.userData).then(async (userData) => {
      if (userData != null && userData != undefined) {
        item.hotelReservationId = userData.hotelReservationId ^ 1001990234;
        this._page.openUrlInFrame(item);
      } else {
        const modal = await this._modalCtrl.create({
          component: SignInPage,
          cssClass: 'my-custom-class',
        });

        this._events.publish(Config.Keys.modalSignIn_added, modal);
        modal.onDidDismiss().then((result) => {
          if (result.data.userSign) {
            this.SurvexWebPageOpen(item);
          } else {
            this._router.navigate(['/home']);
          }
        });

        modal.present();
      }
    });
  }

  HoSpotWebPageOpen(item) {
    this._storage.get(Config.Keys.userData).then(async (userData) => {
      if (userData != null && userData != undefined) {
        let url = item.languageContent[this._page.shortLangCode].webPageUrl;
        url = url.replace('ROOM', userData.roomNo);
        url = url.replace(
          'DATE',
          moment(userData.birthDateFull).format('YYYY.DD.MM')
        );
        item.webPageUrl = url;
        this._page.openUrl(url);
        //this._page.openUrlInFrame(item);
      } else {
        const modal = await this._modalCtrl.create({
          component: SignInPage,
          cssClass: 'my-custom-class',
        });

        this._events.publish(Config.Keys.modalSignIn_added, modal);
        modal.onDidDismiss().then((result) => {
          if (result.data.userSign) {
            this.HoSpotWebPageOpen(item);
          } else {
            this._router.navigate(['/home']);
          }
        });

        modal.present();
      }
    });
  }

  WebPageOpen(item) {
    if (item.isSettings.isLogin == 1) {
      this._storage.get(Config.Keys.userData).then(async (userData) => {
        if (userData != null && userData != undefined) {
          let url = item.languageContent[this._page.shortLangCode].webPageUrl;
          this._page.openUrl(url);
        } else {
          const modal = await this._modalCtrl.create({
            component: SignInPage,
            cssClass: 'my-custom-class',
          });

          this._events.publish(Config.Keys.modalSignIn_added, modal);
          modal.onDidDismiss().then((result) => {
            if (result.data.userSign) {
              let url =
                item.languageContent[this._page.shortLangCode].webPageUrl;
              this._page.openUrl(url);
            } else {
              this._router.navigate(['/home']);
            }
          });

          modal.present();
        }
      });
    } else {
      let url = item.languageContent[this._page.shortLangCode].webPageUrl;
      this._page.openUrl(url);
    }
  }
}
