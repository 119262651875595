import { Component, OnInit, Input } from '@angular/core';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { Config } from 'src/app/app.const';
import { ModalController } from '@ionic/angular';
import { BasketPage } from 'src/app/pages/basket/basket.page';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import { GgEventService } from 'src/app/services/GgEvent/gg-event.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { SpaBasketPage } from 'src/app/pages/spa-basket/spa-basket.page';
import { BasketPrintPage } from 'src/app/pages/basket-print/basket-print.page';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() customClass: any;
  @Input() isBox: any;
  @Input() isSpaBox: any;
  @Input() isNavigate: boolean;
  @Input() isPrinterBox: boolean = false;
  globalAlignment: {
    pageTitleAlign: boolean,
    exploreButton: boolean,
    butonDetailText: boolean
  } = {
      pageTitleAlign: true,
      exploreButton: true,
      butonDetailText: true,
    }
  constructor(
    private _page: GgPageService,
    private _modalController: ModalController,
    private _shoppingCartService: ShoppingCartService,
    private _events: GgEventService,
    private _utility: UtilityService,
    private _storage: GgStorageService
  ) {
    this.page = this._page;
    this._events.subscribe(Config.Keys.shoppingCart_refresh, (data) => {
      this.basketList = data;
      this.updateBasketListCount();
    });

    this._events.subscribe(Config.Keys.printerShoppingCart_refresh, (data) => {
      this.printerBoxList = data;
      this.updatePrinterBasketListCount();
    });

    this._events.subscribe(Config.Keys.spaShoppingCart_refresh, (data) => {
      this.spaBasketList = data;
      this.updateSpaBasketList();
    });

    this._storage.get(Config.Keys.globalAlingment).then(
      (globalAlign) => {
        this.globalAlignment = globalAlign;
      }
    );

    this._events.subscribe(Config.Keys.globalAlingment, (globalAlign) => {
      this.globalAlignment = globalAlign;
    });

    this._storage.get(Config.Keys.fontSettings).then((fonts) => {
      this.headerFont = fonts.header;
    })
  }

  page: GgPageService;
  title = "title";
  printerBoxList: Array<any>;
  basketList: Array<any>;
  spaBasketList: Array<any>;
  boxItemCount: number = 0;
  spaBoxItemCount: number = 0;
  headerFont: string = "Header";
  printerBoxItemCount: number = 0;


  menuToggleButton() {
    this._events.publish('menu-clicked', '');
  }

  ngOnInit() {

    this._events.subscribe(Config.Keys.pageTitle, (title) => {
      this.title = title;
    });
    //TODO boxNumberCount yani sepet içerisindeki toplam item sayısı bir yerden getirilecek ve set edilecek
    this._shoppingCartService.basketList().then((list: Array<any>) => {
      this.basketList = list;
      this.updateBasketListCount();
    });

    this._shoppingCartService.spaBasketList().then((list: Array<any>) => {
      this.spaBasketList = list;
      this.updateSpaBasketList();
    });

    this._shoppingCartService.printerBasketList().then((list: Array<any>) => {
      this.printerBoxList = list;
      this.updatePrinterBasketListCount();
    });
    this.title = this.page.getData(Config.Keys.pageTitle);

  }

  goBack() {
    this.page.goBack();
  }

  goHome() {
    this._storage.set(Config.Keys.isInfo, false).then(() => {
      this._events.publish(Config.Keys.isInfo, false);
      this.page.removeAllDataForParentInfo();
      this.page.goWithoutTitle('home');
      this._shoppingCartService.deleteAll();
      this._shoppingCartService.printerDeleteAll();
    });
  }

  openSettings() {
    this.page.goWithoutTitle('/settings');
  }


  updateBasketListCount() {
    this.boxItemCount = 0;
    for (let i = 0; i < this.basketList.length; i++) {
      this.boxItemCount += this.basketList[i].pax;
    }
  }

  updatePrinterBasketListCount() {
    this.printerBoxItemCount = 0;
    for (let i = 0; i < this.printerBoxList.length; i++) {
      this.printerBoxItemCount += this.printerBoxList[i].pax;
    }
  }

  updateSpaBasketList() {
    this.spaBoxItemCount = 0;
    for (let i = 0; i < this.spaBasketList.length; i++) {
      this.spaBoxItemCount += 1;
    }
  }

  async goBox() {
    const modal = await this._modalController.create({ component: BasketPage });
    modal.present();
  }

  async goPrinterBox() {
    const modal = await this._modalController.create({ component: BasketPrintPage });
    modal.present();
  }

  async goSpaBox() {
    const modal = await this._modalController.create({ component: SpaBasketPage });
    modal.present();
  }


  navigateControl() {
    if (this._utility.isFull(this.isNavigate)) {
      if (this.isNavigate)
        return true;
      else
        return false;
    }
    else
      return true;
  }
}