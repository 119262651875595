export class CustomerMobilLogin {
  roomNo;
  birthDate;
  mobexCustomersId;
  hotelId;
  fullName;
  sex;
  email;
  emailOrjinal;
  password;
  phone;
  constructor() {}
}
