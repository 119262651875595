import { registerPlugin } from '@capacitor/core';

export interface MobileKeysPlugin {
  registerUser(options: { value: string }): Promise<{ value: string }>;
  unregisterUser(): Promise<{ value: string }>;
  scan(): Promise<{ value: string }>;
  listKeys(): Promise<{ value: any[] }>;
}

const MobileKeys = registerPlugin<MobileKeysPlugin>('MobileKeys');
export default MobileKeys;
