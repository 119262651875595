import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ApiService } from 'src/app/services/api/api.service';
import { Config } from 'src/app/app.const';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { GgNotifyService } from 'src/app/services/GgNotify/gg-notify.service';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { GlobalAlignment } from 'src/app/interfaces/GlobalAlignment';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { MarginHeaderService } from 'src/app/services/margin-header/margin-header.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';

@Component({
  selector: 'app-info-detail',
  templateUrl: './info-detail.page.html',
  styleUrls: ['./info-detail.page.scss'],
})
export class InfoDetailPage implements OnInit {
  constructor(
    private _modalController: ModalController,
    private _page: GgPageService,
    private _utility: UtilityService,
    private _apiService: ApiService,
    private _share: SocialSharing,
    private _notify: GgNotifyService,
    private _storage: GgStorageService,
    private _imageViewer: PhotoViewer,
    private _marginHeader: MarginHeaderService,
    private _device: Device
  ) {
    this.page = _page;
  }

  page: GgPageService;
  userData: any;
  pageLanguageContent;
  item: any;
  globalSettings: any;
  topLogo: string = null;
  pageTitleAling: boolean = false;
  title: string = null;
  fontSettings = {
    title: null,
    titleInfo: null,
    header: null,
    text: null,
  };

  async ngOnInit() {

    this.userData = this.page.userData;
    let item = await this._page.getData('item');
    this._storage.get(Config.Keys.globalSettings).then((settings) => {
      this.globalSettings = settings;
      this.topLogo = settings.topLogo;
    });

    this._storage.get(Config.Keys.fontSettings).then((fonts) => {
      this.fontSettings = fonts;
    });

    this._storage
      .get(Config.Keys.globalAlingment)
      .then((resp: GlobalAlignment) => {
        this.pageTitleAling = resp.pageTitleAling;
        this._marginHeader.setMargin();
      });

    if (!this._utility.isNullOrUndefined(item.parametrId)) {
      this._apiService
        .get(
          `${Config.Api.MobexInfos_Detail}/${item.parametrId ? item.parametrId : item.id
          }`
        )
        .subscribe((response) => {
          this.item = response.info;
          this.pageLanguageContent = response.languageContent;
        });

      this._apiService
        .post(Config.Api.MobileStatistics, {
          customerId: this.userData !== null ? this.userData.mobexCustomersId : 0,
          deviceId: this._device.uuid !== null ? this._device.uuid : 'web',
          tableId: item.parametrId ? item.parametrId : item.id,
          tableNo: 1,
          statu: true
        }).subscribe();

    } else {
      this.item = item;
    }
  }

  close() {
    this.closeModal(false);
  }

  closeModal(isSignIn) {
    this._modalController.dismiss({ userSign: isSignIn });
  }

  share() {
    this._notify.show();
    this._share
      .share(
        this.page.removeHTMLElement(
          this.item.languageContent[this.page.shortLangCode].description
        ),
        this.item.languageContent[this.page.shortLangCode].title,
        this.item.imageUrl
      )
      .then(() => {
        this._notify.hide();
      })
      .catch(() => {
        this._notify.hide();
      });
  }

  returnForFab() {
    if (Config.Api.homepageToolbar) {
      return '90px';
    } else {
      return '10px';
    }
  }

  returnForContent() {
    if (Config.Api.homepageToolbar) {
      return true;
    } else {
      return false;
    }
  }
}
