// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  margin: 10px 0px;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/register/register.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;AACJ","sourcesContent":["ion-button {\n    margin: 10px 0px;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
