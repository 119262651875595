import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Config } from 'src/app/app.const';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { OpenPageService } from 'src/app/services/open-page/open-page.service';

@Component({
  selector: 'app-random-campaign',
  templateUrl: './random-campaign.page.html',
  styleUrls: ['./random-campaign.page.scss'],
})
export class RandomCampaignPage implements OnInit {

  page: GgPageService;
  @Input() data: any;
  @Input() langContent: any;

  fontSettings = {
    title: null,
    titleInfo: null,
    header: null,
    text: null
  };
  constructor(
    private _modalCtrl: ModalController,
    private _page: GgPageService,
    private _openPage: OpenPageService,
    private _storage: GgStorageService
  ) {
    this.page = this._page;
  }

  ngOnInit() {

    this._storage.get(Config.Keys.fontSettings).then((fonts) => {
      this.fontSettings = fonts;
    });

  }

  close() {
    this._modalCtrl.dismiss();
  }

  openDetail() {
    this._modalCtrl.dismiss().then(() => {
      this._page.setData(Config.Keys.pageTitle, this.data.languageContent[this._page.shortLangCode].title);
      if (this.data.popupType == 1) {
        this._page.setData('item', { notice: this.data, languageContent: this.langContent });
        this._page.goWithoutTitle('/announcement-detail');
      } else if (this.data.popupType == 2) {
        this._page.setData('item', { campaign: this.data, languageContent: this.langContent });
        this._page.goWithoutTitle('/campaign-detail');
      }


    });
  }
}
