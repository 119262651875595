import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Standalone bileşenleri içe aktarın
import { HeaderComponent } from './header/header.component'; // Bu bileşen standalone olarak işaretlenmiş
import { MyServiceComponent } from './my-service/my-service.component'; // Bu bileşen standalone olarak işaretlenmiş
import { MyServiceDetailComponent } from './my-service-detail/my-service-detail.component'; // Bu bileşen standalone olarak işaretlenmiş
import { PopupCampComponent } from './popup-camp/popup-camp.component'; // Bu bileşen standalone olarak işaretlenmiş

@NgModule({
  declarations: [
    // Standalone bileşenleri buradan çıkarın
    // HeaderComponent,
    // MyServiceComponent,
    // MyServiceDetailComponent,
    // PopupCampComponent,
  ],
  imports: [
    CommonModule,
    // Standalone bileşenleri burada import edin
    HeaderComponent,
    MyServiceComponent,
    MyServiceDetailComponent,
    PopupCampComponent,
  ],
  exports: [
    // Eğer başka modüllerde kullanacaksanız, burada export edebilirsiniz
    HeaderComponent,
    MyServiceComponent,
    MyServiceDetailComponent,
    PopupCampComponent,
  ]
})
export class ComponentsModule { }
