import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from '../../app.const';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private _api: ApiService
  ) { }

  forgotPasswordMail(email: string = null) {
    return this._api.get(Config.Api.Account_ForgotPasswordMail + '/' + email);
  }

  forgotPasswordCustomerID(customerID: string = null) {
    return this._api.get(Config.Api.Account_ForgotPassword + '/' + customerID);
  }
}