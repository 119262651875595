// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-buttons {
  top: 10px;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  text-align: center;
}

.btn-back {
  left: 10px;
  top: 4px;
}
.btn-back ion-icon {
  font-size: 30px;
}

.btn-home,
.btn-box {
  right: 5px;
  top: 2px;
}
.btn-home ion-icon,
.btn-box ion-icon {
  font-size: 30px;
}

.btn-box label {
  position: absolute;
  right: 5px;
  bottom: -1px;
  background: #f53d3d;
  border-radius: 17px;
  font-size: 9px;
  border: 1px solid;
  padding: 3px 0px;
  color: white;
  height: 20px;
  width: 20px;
}

.page-title-one,
.page-title-two {
  text-align: center;
  text-transform: capitalize;
  overflow: hidden;
}

.page-title-one {
  margin: 0 auto;
  width: 80%;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  color: #000000;
  margin-top: 10px;
}

.page-title-two {
  border-bottom: 1px solid;
  width: 80%;
  margin: 65px auto 11px;
  padding-bottom: 5px;
}

ion-title {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAIA;EACI,UAAA;EACA,QAAA;AADJ;AAGI;EACI,eAAA;AADR;;AAKA;;EAEI,UAAA;EACA,QAAA;AAFJ;AAII;;EACI,eAAA;AADR;;AAMI;EACI,kBAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AAHR;;AAOA;;EAEI,kBAAA;EAGA,0BAAA;EACA,gBAAA;AANJ;;AASA;EACI,cAAA;EACA,UAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AANJ;;AASA;EACI,wBAAA;EACA,UAAA;EACA,sBAAA;EACA,mBAAA;AANJ;;AAUA;EACI,kBAAA;AAPJ","sourcesContent":["ion-buttons {\n    top: 10px;\n    position: absolute;\n    width: 40px;\n    height: 40px;\n    margin: 0;\n    padding: 0;\n    text-align: center;\n\n}\n\n\n.btn-back {\n    left: 10px;\n    top: 4px;\n\n    ion-icon {\n        font-size: 30px;\n    }\n}\n\n.btn-home,\n.btn-box {\n    right: 5px;\n    top: 2px;\n\n    ion-icon {\n        font-size: 30px;\n    }\n}\n\n.btn-box {\n    label {\n        position: absolute;\n        right: 5px;\n        bottom: -1px;\n        background: #f53d3d;\n        border-radius: 17px;\n        font-size: 9px;\n        border: 1px solid;\n        padding: 3px 0px;\n        color: white;\n        height: 20px;\n        width: 20px;\n    }\n}\n\n.page-title-one,\n.page-title-two {\n    text-align: center;\n    //font-size: 20px;\n    //font-family: Playfair Display Bold;\n    text-transform: capitalize;\n    overflow: hidden;\n}\n\n.page-title-one {\n    margin: 0 auto;\n    width: 80%;\n    text-overflow: ellipsis;\n    margin-bottom: 10px;\n    color: #000000;\n    margin-top: 10px;\n}\n\n.page-title-two {\n    border-bottom: 1px solid;\n    width: 80%;\n    margin: 65px auto 11px;\n    padding-bottom: 5px;\n}\n\n\nion-title {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
