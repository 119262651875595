import { Injectable, Inject } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Config } from '../../app.const';

@Injectable({
  providedIn: 'root',
})
export class GgStorageService {
  constructor(private _storage: Storage) {
    this._storage.create();
  }

  get(name) {
    return this._storage.get(name + '' + Config.const);
  }

  set(name, value) {
    return this._storage.set(name + '' + Config.const, value);
  }

  remove(name) {
    return this._storage.remove(name + '' + Config.const);
  }

  removeAll() {
    return this._storage.clear();
  }
}
