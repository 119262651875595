import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@Injectable({
  providedIn: 'root',
})
export class GgNetworkService {
  constructor(private _network: Network) {}

  isConnectInternet() {
    return this._network.onConnect();
  }

  isDisconnect() {
    return this._network.onDisconnect();
  }

  onchange() {
    return this._network.onChange();
  }

  connectionType() {
    if (this._network.type == 'none') {
      return false;
    } else {
      return true;
    }
  }

  connectionType2() {
    return new Promise((resolve, reject) => {
      // alert(this._network.type);
      if (this._network.type === 'none') {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}
