import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from 'src/app/app.const';

@Injectable({
  providedIn: 'root'
})
export class GetGlobalSettingsService {

  constructor(
    private api: ApiService
  ) { }

  getGlobalSettings() {
    return this.api.get(`${Config.Api.MobileSettings}/${Config.Version}`);
  }

}
