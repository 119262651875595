import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-camp',
  templateUrl: './popup-camp.component.html',
  styleUrls: ['./popup-camp.component.scss'],
})
export class PopupCampComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
