import { Pipe, PipeTransform } from '@angular/core';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';


@Pipe({
  name: 'languageContent'
})
export class LanguageContentPipe implements PipeTransform {
  constructor() { }

  transform(value: any, ...args: any[]): any {
    let langCode: string = args[0].toLowerCase();
    if (args[1] !== null && args[1] !== undefined) {
      if (args[1] == "description") {
        return value[langCode].titleInfo;
      }
    } else {
      return value[langCode].title;
    }
  }
}