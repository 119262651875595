import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { importProvidersFrom } from '@angular/core';


import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './services/api/api.service';
import { GgEventService } from './services/GgEvent/gg-event.service';
import { GgNetworkService } from './services/GgNetwork/gg-network.service';
import { GgNotifyService } from './services/GgNotify/gg-notify.service';
import { GgPageService } from './services/ggPage/gg-page.service';
import { GgStorageService } from './services/GgStorage/gg-storage.service';
import { Config } from './app.const';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { Network } from '@capacitor/network';
import { PipesModule } from './pipes/pipes.module';
import { OpenPageService } from './services/open-page/open-page.service';
import { ActivityService } from './services/activity/activity.service';
import { ShoppingCartService } from './services/shopping-cart/shopping-cart.service';
import { ComponentsModule } from './components/components.module';
import { HeaderComponent } from './components/header/header.component';
import { Device } from '@capacitor/device';
import { SednaService } from './services/sedna/sedna.service';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { MyServiceComponent } from './components/my-service/my-service.component';
//import { ForegroundService } from '@awesome-cordova-plugins/foreground-service/ngx';
//import { Market } from '@ionic-native/market/ngx';
//import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Keyboard } from '@capacitor/keyboard';
import { StartupService } from './services/startup/startup.service';
import { UnreadCountService } from './services/UnreadCount/unread-count.service';
import { CompCampaignService } from './services/CompCampaign/comp-campaign.service';
import { FreeServiceService } from './services/freeService/free-service.service';
import { GetUserPointService } from './services/GetUserPoint/get-user-point.service';
import { GetUserLastService } from './services/GetUserLast/get-user-last.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { Camera } from '@capacitor/camera';
//import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
//import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
//import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';


const services = [

  ApiService,
  GgEventService,
  GgNetworkService,
  GgNotifyService,
  GgPageService,
  GgStorageService,
  OpenPageService,
  ActivityService,
  ShoppingCartService,
  SednaService,
  StartupService,
  UnreadCountService,
  CompCampaignService,
  FreeServiceService,
  GetUserPointService,
  GetUserLastService,
];



bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      AppRoutingModule,
      IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
      HttpClientModule,
      IonicStorageModule.forRoot()
    ),
    ...services,
    { provide: 'CONFIG', useValue: Config },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'tr-TR' },
  ],
}).catch((err) => console.error(err));

export class AppModule { }