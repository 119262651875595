import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Config } from 'src/app/app.const';
import { SignInPage } from 'src/app/pages/sign-in/sign-in.page';
import { ApiService } from '../api/api.service';
import { GgEventService } from '../GgEvent/gg-event.service';
import { GgNotifyService } from '../GgNotify/gg-notify.service';
import { GgPageService } from '../ggPage/gg-page.service';
import { GgStorageService } from '../GgStorage/gg-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(
    private _storage: GgStorageService,
    private ggPage: GgPageService,
    private _modal: ModalController,
    private _events: GgEventService,
    private _api: ApiService,
    private alertCtrl: AlertController,
    private _notify: GgNotifyService,
    private router: Router
  ) {}

  GetSurvey(params) {
    if (this.ggPage.userData !== null && this.ggPage.userData !== undefined) {
      this._notify.show().then(() => {
        this._api.get(`${Config.Api.MobexSurveys}/${params.id}`).subscribe(
          (response) => {
            this.ShowAlertFunc(response);
          },
          (error) => {
            console.log(error);
            this._notify.hide();
          }
        );
      });
    } else {
      this.openSignPage(params);
    }
  }

  async ShowAlertFunc(resp) {
    var self = this;

    let ddata = [];
    if (resp.answersCount == 2) {
      ddata.push({
        text: resp.mobileLanguagePageContent[this.ggPage.shortLangCode].yes,
        handler: () => {
          self.yesButton(resp);
        },
      });

      ddata.push({
        text: resp.mobileLanguagePageContent[this.ggPage.shortLangCode].no,
        role: 'cancel',
        handler: () => {
          self.noButton(resp);
        },
      });
    } else {
      ddata.push({
        text: resp.mobileLanguagePageContent[this.ggPage.shortLangCode].yes,
        handler: () => {
          self.yesButton(resp);
        },
      });

      ddata.push({
        text: resp.mobileLanguagePageContent[this.ggPage.shortLangCode].no,
        role: 'cancel',
        handler: () => {
          self.noButton(resp);
        },
      });

      ddata.push({
        text: resp.mobileLanguagePageContent[this.ggPage.shortLangCode].notUse,
        role: 'info',
        handler: () => {
          self.notUseButton(resp);
        },
      });
    }

    let alert = await this.alertCtrl.create({
      header: resp.languageContent[this.ggPage.shortLangCode].title,
      id: 'dailyActSurveyAlert',
      message: resp.languageContent[this.ggPage.shortLangCode].description,
      buttons: ddata,
      backdropDismiss: false,
    });

    this._notify.hide();
    alert.present();
  }

  yesButton(response) {
    this._notify.show().then(() => {
      this._storage.get(Config.Keys.userData).then((userData) => {
        this.postForOrderSurveys(response, '', 1, userData).subscribe(
          (resp) => {
            this._notify.simpleAlertShow(
              '',
              response.mobileLanguagePageContent[this.ggPage.shortLangCode]
                .success,
              response.mobileLanguagePageContent[this.ggPage.shortLangCode].ok
            );
            this._notify.hide();
          },
          (err) => {
            this._notify.hide();
          }
        );
      });
    });
  }

  async noButton(response) {
    let alert = await this.alertCtrl.create({
      header:
        response.mobileLanguagePageContent[this.ggPage.shortLangCode]
          .no_textbox_inner_text,
      inputs: [
        {
          name: 'reason',
          id: 'reason',
          type: 'textarea',
          placeholder:
            response.mobileLanguagePageContent[this.ggPage.shortLangCode]
              .no_textbox_text,
          attributes: { rows: 10 },
        },
      ],
      cssClass: 'custom-user-message-send-modal-css',
      backdropDismiss: false,
      buttons: [
        {
          text: response.mobileLanguagePageContent[this.ggPage.shortLangCode]
            .ok,
          handler: (inputData) => {
            let reason = inputData.reason;
            if (
              reason !== null &&
              reason !== undefined &&
              reason.trim() !== '' &&
              reason.trim().length > 6
            ) {
              this._notify.show().then(() => {
                this._storage.get(Config.Keys.userData).then((userData) => {
                  this.postForOrderSurveys(
                    response,
                    reason,
                    2,
                    userData
                  ).subscribe(
                    (resp) => {
                      this._notify.simpleAlertShow(
                        '',
                        response.mobileLanguagePageContent[
                          this.ggPage.shortLangCode
                        ].success,
                        response.mobileLanguagePageContent[
                          this.ggPage.shortLangCode
                        ].ok
                      );
                      this._notify.hide();
                    },
                    (err) => {
                      this._notify.hide();
                    }
                  );
                });
              });
            } else {
              this.noButton(response);
            }
          },
        },
      ],
    });

    alert.present();
  }

  notUseButton(response) {
    this._notify.show().then(() => {
      this._storage.get(Config.Keys.userData).then((userData) => {
        this.postForOrderSurveys(response, '', 3, userData).subscribe(
          (resp) => {
            this._api
              .get(
                `${Config.Api.MobexCampaigns_detail}/${response.campaignsId}`
              )
              .subscribe(
                (campResp) => {
                  this._notify.hide();
                  this.ggPage.setData('item', campResp);
                  this.ggPage.go(
                    'campaign-detail',
                    campResp.campaign.languageContent[this.ggPage.shortLangCode]
                      .title
                  );
                },
                (err) => {
                  this._notify.hide();
                }
              );
            this.router.navigate(['']);
          },
          (err) => {
            this._notify.hide();
          }
        );
      });
    });
  }

  // {
  //   "description": "string",
  //   "answer": 0,
  //   "customerId": "string",
  //   "hotelReservationId": 0,
  //   "customerName": "string",
  //   "customerEmail": "string",
  //   "customerPhone": "string",
  //   "orderName": "string",
  //   "roomNo": "string",
  //   "orderId": 0,
  //   "useDate": "2021-08-09T11:35:32.626Z",
  //   "price": 0,
  //   "priceCurrencyTypeId": 0,
  //   "point": 0,
  //   "pointType": true,
  //   "pax": 0,
  //   "birthDate": "string"
  // }
  postForOrderSurveys(response, desc: string = '', answer: number, userData) {
    return this._api.post(Config.Api.MobexOrders_Survey, {
      description: desc,
      answer: answer,
      customerId: userData.hotelReservationId,
      hotelReservationId: userData.hotelId,
      customerName: userData.fullName,
      customerEmail: userData.email,
      customerPhone: userData.phone,
      orderName: 'Surveys',
      roomNo: userData.roomNo,
      orderId: response.id,
      useDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      price: 0,
      point: 0,
      pointType: true,
      pax: 1,
      birthDate: userData.birthDate + '',
    });
  }

  async openSignPage(params) {
    const modal = await this._modal.create({
      component: SignInPage,
      cssClass: 'my-custom-class',
    });

    this._events.publish(Config.Keys.modalSignIn_added, modal);
    modal.onDidDismiss().then((result) => {
      if (
        result.data !== null &&
        result.data !== undefined &&
        result.data.userSign !== false
      ) {
        this.GetSurvey(params);
      }
    });
    modal.present();
  }
}
