export class HotelConfig {
    ownerGroupId: string = null;
    constructor(ownerGroupId: string) {
        this.ownerGroupId = ownerGroupId;

        if (ownerGroupId == null) {
            //ownerGroupID boş gelirse default veri atanıyor.
            ownerGroupId == null;
        }
    }
}