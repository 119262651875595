import { Component, Input, OnInit } from '@angular/core';
import { Config } from 'src/app/app.const';
import { RemainingText } from 'src/app/interfaces/RemainingText';
import { GgEventService } from 'src/app/services/GgEvent/gg-event.service';
import { UtilityService } from 'src/app/services/utility/utility.service';

@Component({
  selector: 'app-my-service',
  templateUrl: './my-service.component.html',
  styleUrls: ['./my-service.component.scss'],
})
export class MyServiceComponent implements OnInit {

  @Input() customClass: any;
  @Input() item: any;
  @Input() index: any;

  constructor(
    private _utility: UtilityService

  ) {

  }

  selectedItem: any;
  rate;

  ngOnInit() {
  }

  showDetail() {
    this.selectedItem = this.item;
  }

  hideDetail() { this.selectedItem = undefined; }

  onRateChange(event) {
    this.selectedItem.rate = this.rate;
  }

  cancelReservation(event) {
    event.stopPropagation();
    //selected reservation
  }

  sendEvaluation() {
    if (this.rate > 0) {
      //send evaluation ....
    }
    else {
      //please choose rate of evaluation
    }
  }

  clearRate() {
    this.rate = 0;
    this.selectedItem.rate = undefined;
  }

  clickEmpty(event) {
    event.stopPropagation();
  }
}