import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarginHeaderService {

  constructor() {

  }


  setMargin() {
    setTimeout(() => {
      if (screen.width > 460) {
        var refElement = document.getElementsByClassName('special-scroll-content')[0].clientHeight;
        document.getElementsByClassName('special-scroll-content')[0].setAttribute('style', 'margin-bottom: ' + refElement + 'px !important;');
      }
    }, 1500);
  }
}
