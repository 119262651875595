// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reservation-info {
  background-color: rgba(0, 0, 0, 0.7215686275);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}
.reservation-info div {
  background: white;
  margin: auto;
  width: 100%;
  padding: 10px 40px 25px;
}
.reservation-info div h2 {
  text-align: center;
  font-size: 25px;
}
.reservation-info div p {
  display: flex;
  color: black;
  font-size: 20px;
}
.reservation-info div p ion-icon {
  margin-right: 10px;
  margin-top: 3px;
  color: black;
}
.reservation-info div .btn-cancel {
  width: 100%;
  display: none;
}
.reservation-info div .rate-area {
  display: none;
}
.reservation-info div .rate-area .rate-clear {
  border-bottom: 1px solid;
  margin: 5px auto;
  color: gray;
  display: block;
  width: fit-content;
  font-size: 12px;
}
.reservation-info div .rate-area ion-button {
  display: block;
  margin: 14px 0px 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/my-service-detail/my-service-detail.component.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;AACJ;AACI;EACI,iBAAA;EACA,YAAA;EACA,WAAA;EACA,uBAAA;AACR;AACQ;EACI,kBAAA;EACA,eAAA;AACZ;AAEQ;EACI,aAAA;EACA,YAAA;EACA,eAAA;AAAZ;AAEY;EACI,kBAAA;EACA,eAAA;EACA,YAAA;AAAhB;AAIQ;EACI,WAAA;EACA,aAAA;AAFZ;AAKQ;EACI,aAAA;AAHZ;AAKY;EACI,wBAAA;EACA,gBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;AAHhB;AAMY;EACI,cAAA;EACA,oBAAA;AAJhB","sourcesContent":[".reservation-info {\n    background-color: #000000b8;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n\n    div {\n        background: white;\n        margin: auto;\n        width: 100%;\n        padding: 10px 40px 25px;\n\n        h2 {\n            text-align: center;\n            font-size: 25px;\n        }\n\n        p {\n            display: flex;\n            color: black;\n            font-size: 20px;\n\n            ion-icon {\n                margin-right: 10px;\n                margin-top: 3px;\n                color: black;\n            }\n        }\n\n        .btn-cancel {\n            width: 100%;\n            display: none;\n        }\n\n        .rate-area {\n            display: none;\n\n            .rate-clear {\n                border-bottom: 1px solid;\n                margin: 5px auto;\n                color: gray;\n                display: block;\n                width: fit-content;\n                font-size: 12px;\n            }\n\n            ion-button {\n                display: block;\n                margin: 14px 0px 0px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
