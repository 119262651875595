// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-self {
  width: 100%;
}

.buttons {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 0px;
}
.buttons ion-row ion-col {
  padding: 0px !important;
}
.buttons ion-row ion-col ion-button {
  margin: 0px;
}

.open-button {
  --background: #98845f !important;
  --background-activated: #98845f !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/random-campaign/random-campaign.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;AACJ;AAEQ;EACI,uBAAA;AAAZ;AACY;EACI,WAAA;AAChB;;AAKA;EACI,gCAAA;EACA,0CAAA;AAFJ","sourcesContent":[".image-self{\n    width: 100%;\n}\n\n.buttons{\n    position: absolute;\n    bottom: 0px;\n    left: 0px;\n    width: 100%;\n    padding: 0px;\n\n    ion-row{\n        ion-col{\n            padding: 0px !important;\n            ion-button{\n                margin: 0px;\n            }\n        }\n    }\n}\n\n.open-button{\n    --background: #98845f !important;\n    --background-activated: #98845f !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
