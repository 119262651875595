import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SednaService {

  constructor(private api: ApiService) { }

  SednaLogin(url, loginString) {
    let loginData: Object = JSON.parse(loginString);
    return this.api.postForSedna(url, loginData, null, null)
  }

  SednaTraceOpen(url, data, token) {
    return this.api.postForSedna(url, data, null, token)
  }
}
