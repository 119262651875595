import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IonButton,
  IonInput,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { ApiService } from 'src/app/services/api/api.service';
import { GgEventService } from 'src/app/services/GgEvent/gg-event.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { GgNotifyService } from 'src/app/services/GgNotify/gg-notify.service';
import { Config } from 'src/app/app.const';
import { CustomerMobilLogin } from 'src/app/interfaces/CustomerMobilLogin';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { ResetMailAndPassword } from 'src/app/interfaces/ResetMailAndPassword';
import { Login } from 'src/app/interfaces/Login';
import OneSignal from 'onesignal-cordova-plugin';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { RegisterPage } from '../register/register.page';
import { AccountService } from 'src/app/services/account/account.service';
import { InfoDetailPage } from '../info-detail/info-detail.page';
import { ConfirmSmsPage } from '../confirm-sms/confirm-sms.page';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss'],
})
//sezgin abi selamlar
export class SignInPage implements OnInit {
  customerMobilLogin: CustomerMobilLogin = new CustomerMobilLogin();
  RoomNo: string;
  BirthDate: string;
  Email: string;
  orjEmail: string;
  Password: string;
  PasswordAgain: string;
  FullName: string;
  roomNoLetter: string = null;

  kvkk1: boolean = true;
  kvkk2: boolean = true;

  roomNoBirthDateArea = true;
  emailAvaible: boolean = true;
  passwordAvaible: boolean = false;
  isLetterAvailable: boolean = false;
  blockRoute: string = 'left';

  email: string = null;
  phone: string = null;
  passwordLogin: string = null;
  playerId: string = null;
  logo: string = null;
  yearsList: Array<string> = [];
  monthList: Array<string> = [];
  dayList: Array<string> = [];

  selectedDay: string = null;
  selectedMonth: string = null;
  selectedYear: string = null;
  Accept: boolean = false;
  EmailData: boolean = false;
  buttonDisabled: boolean = true;
  emailDisabled: boolean = true;
  passwordDisabled: boolean = true;
  smsPageLang: any;
  registerButtonActiveStatus: boolean = false;

  constructor(
    private _modalController: ModalController,
    private _page: GgPageService,
    private _apiService: ApiService,
    private _events: GgEventService,
    private _utility: UtilityService,
    private _notify: GgNotifyService,
    private _storage: GgStorageService,
    private _device: Device,
    private _platform: Platform,
    private _account: AccountService
  ) {
    this.page = _page;

    this.generateYears();
    this.generateMonths();
    this.generateDays();
  }

  page: GgPageService;
  pageLanguageContent;
  loginViaEmail: boolean = false;

  openSmsVerification() {
    this._apiService
      .get(Config.Api.StaticPageMobileLanguages + '/SmsPage')
      .subscribe((resp) => {
        this.smsPageLang = resp.languageContent;
        if (
          this.customerMobilLogin.phone !== null &&
          this.customerMobilLogin.phone !== undefined &&
          this.customerMobilLogin.phone.trim() !== ''
        ) {
          this.smsModal();
        } else {
          this._notify.confirm2(
            null,
            this.smsPageLang[this.page.shortLangCode].youDontHavePhone,
            this.pageLanguageContent[this.page.shortLangCode].Ok,
            () => {
              this.close();
            }
          );
        }
      });
  }

  async smsModal() {
    const clockmodal = await this._modalController.create({
      component: ConfirmSmsPage,
      componentProps: this.customerMobilLogin,
      cssClass: 'special-sms-confirm-modal',
    });

    clockmodal.present();

    clockmodal.onDidDismiss().then((closeData) => {
      if (closeData.role != 'accepted_code') {
        this.close();
      }
    });
  }

  generateYears() {
    let max = new Date().getFullYear();
    let min = max - 100;
    let years = [];
    for (var i = max; i >= min; i--) {
      years.push(i.toString());

      if (i == min) {
        this.yearsList = years;
      }
    }
  }

  testData(data1, data2) { }

  generateMonths() {
    let max = 12;
    let months = [];
    for (let i = 1; i <= max; i++) {
      months.push(i.toString());
      if (i == max) {
        this.monthList = months;
      }
    }
  }

  generateDays() {
    let max = 31;
    let days = [];
    for (let i = 1; i <= max; i++) {
      days.push(i.toString());
      if (i == max) {
        this.dayList = days;
      }
    }
  }
  async openInfoDetailForAcceptions() {
    const modal = await this._modalController.create({
      component: InfoDetailPage,
      cssClass: 'my-custom-class',
    });

    modal.present();
  }

  updateAccept() {
    if (
      this.pageLanguageContent[this.page.shortLangCode].LightingTextId != null
    ) {
      this._page.setData('item', {
        parametrId:
          this.pageLanguageContent[this.page.shortLangCode].LightingTextId,
        id: this.pageLanguageContent[this.page.shortLangCode].LightingTextId,
        title: null,
      });
      this.openInfoDetailForAcceptions();
    }
    if (!this.Accept) {
      this.buttonDisabled = true;
      this.registerButtonActiveStatus = false;
    } else {
      this.buttonDisabled = false;
      this.registerButtonActiveStatus = true;
    }
  }

  updateEmailData() {
    if (this.EmailData) {
      this.emailDisabled = true;
    } else {
      this.emailDisabled = false;
    }
  }

  ngOnInit() {
    this._storage.get('LightingTextCheck').then((buttonDisabled) => {
      if (buttonDisabled != null) {
        this.buttonDisabled = buttonDisabled;
        this.kvkk2 = buttonDisabled;
        this.registerButtonActiveStatus = buttonDisabled;
      }
    });

    this._storage.get('EmailData').then((emailDisabled) => {
      if (emailDisabled != null) {
        this.emailDisabled = emailDisabled;
        this.kvkk1 = !emailDisabled;
      }
    });

    this._storage.get(Config.Keys.globalSettings).then((globalSetting) => {
      this.loginViaEmail = globalSetting.loginViaEmail;
      this._apiService.get(Config.Api.MobileRegisters).subscribe(
        (response) => {
          this.pageLanguageContent = response.languageContent;
          this.isLetterAvailable =
            this.pageLanguageContent[this.page.shortLangCode].BlockActive;
          this.blockRoute =
            this.pageLanguageContent[this.page.shortLangCode].BlockRoute;

          if (
            this.pageLanguageContent[this.page.shortLangCode].LightingTextId ===
            null
          ) {
            this.buttonDisabled = false;
            this.kvkk2 = false;
            this.registerButtonActiveStatus = true;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this._storage.get(Config.Keys.globalSettings).then((globalSetting) => {
      this.logo = globalSetting.whiteLogo;
    });
  }

  close() {
    this.closeModal(false);
  }

  closeModal(isSignIn) {
    this._modalController.dismiss({ userSign: isSignIn }).then(() => {
      if (this.loginViaEmail && !isSignIn) {
        this.page.goBack();
      }
    });
  }

  async getAccount() {
    const modal = await this._modalController.create({
      component: RegisterPage,
      cssClass: 'my-custom-class',
    });

    modal.onDidDismiss().then((result) => { });
    await modal.present();
  }

  loginEmail() {
    this._apiService
      .post(Config.Api.Account_Login_Email, {
        email: this.email,
        password: this.passwordLogin,
      })
      .subscribe(
        (resp: any) => {
          this.customerMobilLogin = resp;
          this.saveUserLocal();
        },
        (error) => {
          console.log(error);
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].WrongPassword,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
        }
      );
  }

  signIn() {
    try {
      OneSignal.getDeviceState((ids) => {
        this.playerId = !this._utility.isNullOrUndefined(ids.userId)
          ? ids.userId
          : Config.Keys.developer;
      });
    } catch (error) {
      console.log(error);
      this.playerId = Config.Keys.developer;
    }

    var login: Login = new Login();
    if (this.isLetterAvailable) {
      if (this.blockRoute == 'left')
        login.RoomNo = this.roomNoLetter + this.RoomNo;
      else
        login.RoomNo =
          this.roomNoLetter != null
            ? this.RoomNo + '-' + this.roomNoLetter
            : this.RoomNo;
    } else login.RoomNo = this.RoomNo;


    login.BirthDate = parseInt(this.selectedYear);
    login.BirthDateFull = `${this.selectedYear}.${this.selectedMonth}.${this.selectedDay}`;
    login.PlayerId = this.playerId;
    if (
      !this._utility.isNullOrUndefined(login.RoomNo) &&
      !this._utility.isNullOrUndefined(login.BirthDate) &&
      !this._utility.isNullOrUndefined(this.selectedYear) &&
      !this._utility.isNullOrUndefined(this.selectedMonth) &&
      !this._utility.isNullOrUndefined(this.selectedDay)
    ) {
      this._notify.show().then(() => {
        this._apiService.post(Config.Api.Account_Login, login).subscribe(
          (res: any) => {
            this.customerMobilLogin = res;
            this.phone = res.phone;
            this._storage
              .get(Config.Keys.globalSettings)
              .then((globSet: any) => {
                // if (globSet.sms) {
                //   this.openSmsVerification();
                // }

                this.emailAvaible = !this._utility.isNullOrUndefined(
                  this.customerMobilLogin.email
                );
                this.passwordAvaible = !this._utility.isNullOrUndefined(
                  this.customerMobilLogin.password
                );
                this.FullName = this.customerMobilLogin.fullName;
                this.Email = this._utility.emailCryptoView(
                  this.customerMobilLogin.email
                );

                this.passwordDisabled =
                  this.pageLanguageContent[
                    this.page.shortLangCode
                  ].PasswordPage;
                this.orjEmail = this.customerMobilLogin.emailOrjinal;
                this.roomNoBirthDateArea = false;
                this._notify.hide();
              });
          },
          (error) => {
            this.roomNoBirthDateArea = true;

            this._notify.simpleAlertShow(
              this.pageLanguageContent[this.page.shortLangCode].Warning,
              this.pageLanguageContent[this.page.shortLangCode].OneErrorOccur,
              this.pageLanguageContent[this.page.shortLangCode].Ok
            );
            this._notify.hide();
            console.log(error);
          }
        );
      });
    } else {
      this._notify.simpleAlertShow(
        this.pageLanguageContent[this.page.shortLangCode].Warning,
        this.pageLanguageContent[this.page.shortLangCode].LeaveNoSpace,
        this.pageLanguageContent[this.page.shortLangCode].Ok
      );
    }
  }

  register() {
    this._storage.set('EmailData', this.emailDisabled);
    this._storage.set('LightingTextCheck', this.buttonDisabled);

    if (!this.emailAvaible) {
      if (this.emailDisabled) {
        if (!this._utility.isEmail(this.Email)) {
          //kullanıcının hali hazırda bir email adresi yok ise ve email alanı boş ise uyarı verdiriyoruz.
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].PleaseValidEmail,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
          return;
        }
        this.customerMobilLogin.email = this.Email;
      }
    }

    // Fly Dovolena

    /*     if (this._utility.isNullOrUndefined(this.phone)) {
          //kullanıcının hali hazırda bir telefon adresi yok ise ve telefon alanı boş ise uyarı verdiriyoruz.
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].LeaveNoSpace,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
          return;
        }
    
        if (this.phone.length < 10) {
          //kullanıcının telefon numrası kontrol uyarısı.
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].PhoneCheck,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
          return;
        } */

    // Fly Dovolena End



    if (this.emailAvaible) {
      //kullanıcının hali hazırda bir email adresi var mı ?
      if (this._utility.isEmpty(this.Email)) {
        this._notify.simpleAlertShow(
          this.pageLanguageContent[this.page.shortLangCode].Warning,
          this.pageLanguageContent[this.page.shortLangCode].LeaveNoSpace,
          this.pageLanguageContent[this.page.shortLangCode].Ok
        );
        return;
      }

      if (this._utility.isCryptoEmailView(this.Email)) {
        //kullanıcı email adresini güncellemedi
        this.customerMobilLogin.email = this.orjEmail;
      } else {
        //kullanıcı yeni bir email adresi girdi
        if (!this._utility.isEmail(this.Email)) {
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].PleaseValidEmail,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
          return;
        }
        //yeni girilen ve formata uygun olan email objeye aktarılıyor.
        this.customerMobilLogin.email = this.Email;
      }
    }

    if (this.passwordDisabled) {
      if (!this._utility.isEmpty(this.Password)) {
        if (!this.passwordAvaible) {
          //kullanıcının hali hazırda bir parolası yok (hiç belirlenmemiş) kullanıcı yeni bir parola belirleyecek
          if (!this._utility.isEmpty(this.PasswordAgain)) {
            if (this.Password == this.PasswordAgain) {
              //parolalar birbirine eşit ise ve tabiki boş değillerse parolay objeye aktarılıyor
              this.customerMobilLogin.password = this.Password;
              this.sendRegisterRequest();
            } else
              this._notify.simpleAlertShow(
                this.pageLanguageContent[this.page.shortLangCode].Warning,
                this.pageLanguageContent[this.page.shortLangCode]
                  .PasswordsNotSame,
                this.pageLanguageContent[this.page.shortLangCode].Ok
              );
          } else
            this._notify.simpleAlertShow(
              this.pageLanguageContent[this.page.shortLangCode].Warning,
              this.pageLanguageContent[this.page.shortLangCode].LeaveNoSpace,
              this.pageLanguageContent[this.page.shortLangCode].Ok
            );
        } else {
          if (this.Password == this.customerMobilLogin.password) {
            //kullanıcı girişi başarılı
            if (this._utility.isCryptoEmailView(this.Email)) {
              //değişen kullanıcı verisi yok, şifre doğru girildi, API isteği göndermeye gerek yok
              this.saveUserLocal();
              //alert('awesome user');
            } else {
              //kullanıcı bilgilerinin güncellenmesi için API isteği gönderilecek
              this.sendRegisterRequest();
            }
          } else
            this._notify.simpleAlertShow(
              this.pageLanguageContent[this.page.shortLangCode].Warning,
              this.pageLanguageContent[this.page.shortLangCode].WrongPassword,
              this.pageLanguageContent[this.page.shortLangCode].Ok
            );
        }
      } else
        this._notify.simpleAlertShow(
          this.pageLanguageContent[this.page.shortLangCode].Warning,
          this.pageLanguageContent[this.page.shortLangCode].LeaveNoSpace,
          this.pageLanguageContent[this.page.shortLangCode].Ok
        );
    } else this.sendRegisterRequest();
  }

  sendRegisterRequest() {
    var resetMailAndPassword: ResetMailAndPassword = new ResetMailAndPassword();
    resetMailAndPassword.mobexCustomersId =
      this.customerMobilLogin.mobexCustomersId;
    resetMailAndPassword.mail = this.customerMobilLogin.email;
    resetMailAndPassword.password = this.customerMobilLogin.password;
    resetMailAndPassword.phone = this.phone;

    this._apiService
      .post(Config.Api.Account_ResetMailAndPassword, resetMailAndPassword)
      .subscribe(
        (res) => {
          this.saveUserLocal();
        },
        (error) => {
          console.log(error);
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].OneErrorOccur,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
        },
        () => { }
      );
  }

  forgotPassword() {
    this._apiService
      .get(
        Config.Api.Account_ForgotPassword +
        '/' +
        this.customerMobilLogin.mobexCustomersId
      )
      .subscribe(
        (res) => {
          this._notify.confirm2(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode]
              .PasswordSendToEmail,
            this.pageLanguageContent[this.page.shortLangCode].Ok,
            () => {
              this._modalController.dismiss();
              this.page.goWithoutTitle('home');
            }
          );
        },
        (err) => {
          this._notify.simpleAlertShow(
            this.pageLanguageContent[this.page.shortLangCode].Warning,
            this.pageLanguageContent[this.page.shortLangCode].OneErrorOccur,
            this.pageLanguageContent[this.page.shortLangCode].Ok
          );
        }
      );
  }

  forgotPasswordMail() {
    this._notify.customInputAlert(
      null,
      this.pageLanguageContent[this.page.shortLangCode].DescriptionPassword,
      this.pageLanguageContent[this.page.shortLangCode].Cancel,
      () => { },
      this.pageLanguageContent[this.page.shortLangCode].Ok,
      (emailData) => {
        this._account.forgotPasswordMail(emailData.userEmail).subscribe(
          () => {
            this._notify.simpleAlertShow(
              this.pageLanguageContent[this.page.shortLangCode].Warning,
              this.pageLanguageContent[this.page.shortLangCode]
                .PasswordSendToEmail,
              this.pageLanguageContent[this.page.shortLangCode].Ok
            );
          },
          () => {
            this._notify.simpleAlertShow(
              this.pageLanguageContent[this.page.shortLangCode].Warning,
              this.pageLanguageContent[this.page.shortLangCode].OneErrorOccur,
              this.pageLanguageContent[this.page.shortLangCode].Ok
            );
          }
        );
      },
      [
        {
          placeholder: this.pageLanguageContent[this.page.shortLangCode].Email,
          name: 'userEmail',
          type: 'email',
        },
      ]
    );
  }

  saveUserLocal() {
    if (!this._platform.is('mobileweb') && !this._platform.is('desktop')) {
      OneSignal.getDeviceState((ids) => {
        this._storage.get(Config.Keys.register).then((registerData) => {
          let ddata = {
            PlayerId: !this._utility.isNullOrUndefined(ids.userId)
              ? ids.userId
              : Config.Keys.developer,
            DeviceId: !this._utility.isNullOrUndefined(this._device.uuid)
              ? this._device.uuid
              : Config.Keys.developer,
            PlatformId:
              !this._platform.is('mobileweb') && !this._platform.is('desktop')
                ? this._platform.is('android')
                  ? 0
                  : 1
                : this._platform.is('ios'),
            customerId: this.customerMobilLogin.mobexCustomersId,
            Lang: this.page.shortLangCode,
            Version:
              registerData !== null ? registerData.Version : Config.Version,
            Contract: registerData !== null ? registerData.Contract : false,
            NotifyPermision:
              registerData !== null ? registerData.NotifyPermision : true,
          };
          this._apiService.post(Config.Api.MobileRegisters, ddata).subscribe(
            (resp) => {
              this._storage.set(Config.Keys.userData, this.customerMobilLogin);
              this._events.publish(
                Config.Keys.userData_refresh,
                this.customerMobilLogin
              );
              this.closeModal(true);
            },
            (err) => { }
          );
        });
      });
    } else {
      let ddata = {
        PlayerId: Config.Keys.developer,
        DeviceId: !this._utility.isNullOrUndefined(this._device.uuid)
          ? this._device.uuid
          : Config.Keys.developer,
        PlatformId:
          !this._platform.is('mobileweb') && !this._platform.is('desktop')
            ? this._platform.is('android')
              ? 0
              : 1
            : this._platform.is('ios'),
        Lang: this.page.shortLangCode,
        Version: Config.Version,
        Contract: true,
        NotifyPermision: true,
      };
      this._apiService.post(Config.Api.MobileRegisters, ddata).subscribe(
        (resp) => {
          this._storage.set(Config.Keys.userData, this.customerMobilLogin);
          this._events.publish(
            Config.Keys.userData_refresh,
            this.customerMobilLogin
          );
          this.closeModal(true);
        },
        (err) => { }
      );
    }
  }
}
