import { NgModule } from '@angular/core';

//Pipes
import { TrustedPipe } from './trusted/trusted.pipe';
import { LanguageContentPipe } from './language-content/language-content.pipe';
import { MomentFormatterPipe } from './moment-formatter/moment-formatter.pipe';
import { DataCheckerPipe } from './DataChecker/data-checker.pipe';


// Pipes

@NgModule({
    declarations: [
    ],
    imports: [
        TrustedPipe,
        LanguageContentPipe,
        MomentFormatterPipe,
        DataCheckerPipe
    ],
    exports: [
        TrustedPipe,
        LanguageContentPipe,
        MomentFormatterPipe,
        DataCheckerPipe
    ]
})
export class PipesModule { }