import { Injectable } from '@angular/core';
import { Config } from '../../app.const';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GetUserPointService {

  constructor(private _api: ApiService) { }

  GetUserPoint(customerID) {
    return this._api.get(Config.Api.MobexOrder_UserPoint + '/' + customerID);
  }
}
