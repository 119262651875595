import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Config } from 'src/app/app.const';
import { GgNotifyService } from 'src/app/services/GgNotify/gg-notify.service';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { ApiService } from 'src/app/services/api/api.service';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';//ngClass,ngStyle
import { FormsModule } from '@angular/forms';//ngModel

@Component({
  selector: 'app-confirm-sms',
  templateUrl: './confirm-sms.page.html',
  styleUrls: ['./confirm-sms.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule],
})
export class ConfirmSmsPage implements OnInit {
  timer: number = 180;
  logincode: any = null;
  page: any;
  pageLang: any = null;
  requestCode: any = null;
  controlCountBlock: boolean = true;
  @Input() phone: any;
  constructor(
    private _modalCtlr: ModalController,
    private _storage: GgStorageService,
    private _api: ApiService,
    private _page: GgPageService,
    private _notify: GgNotifyService
  ) {
    this.page = this._page;
  }

  closeModal(role) {
    this._modalCtlr.dismiss(null, role);
  }
  ngOnInit() {
    this._notify.infiniteShow().then(() => {
      this._api
        .get(Config.Api.StaticPageMobileLanguages + '/SmsPage')
        .subscribe((resp) => {
          this.pageLang = resp.languageContent;
          this._api
            .post(Config.Api.SMS, {
              Telephone: this.phone,
              shortLangCode: this.page.shortLangCode,
            })
            .subscribe(
              (smsResponse: any) => {
                this._notify.infiniteClose();
                this.requestCode = smsResponse.code;
              },
              (err) => {
                console.log(err);
                this._notify.infiniteClose();
                this._notify.confirm2(
                  '',
                  err.error.mesaage,
                  this.pageLang[this._page.shortLangCode].Ok,
                  () => {
                    this.closeModal('nonumber');
                  }
                );
              }
            );
        });
    });
  }

  ConfirmCode() {
    if (this.logincode.length === 6) {
      if (this.logincode.toString() === this.requestCode.toString()) {
        this.closeModal('accepted_code');
      } else {
        this._notify.confirm2(
          null,
          this.pageLang[this.page.shortLangCode].wrongCode,
          this.pageLang[this.page.shortLangCode].Ok,
          () => {
            this.closeModal('wrongcode');
          }
        );
      }
    }
  }

  ionViewDidEnter() {
    this.customEventCreate();
  }

  ionViewWillLeave() {
    this.controlCountBlock = false;
  }

  codeChange(event) {
    this.logincode = event.target.value;
  }

  customEventCreate() {
    setTimeout(() => {
      this.timer = this.timer - 1;
      if (this.timer != 0 && this.controlCountBlock) {
        this.customEventCreate();
      } else {
        if (this.timer == 0) {
          this._notify.confirm2(
            null,
            this.pageLang[this.page.shortLangCode].timeout,
            this.pageLang[this.page.shortLangCode].Ok,
            () => {
              this.closeModal('timeout');
            }
          );
        }
      }
    }, 1000);
  }
}
