import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Config } from '../../app.const';

@Injectable({
  providedIn: 'root'
})
export class FreeServiceService {

  constructor(private _api: ApiService) { }

  PostFreeService(data: {
    "customerId": string,
    // "secondOwnerId": number,
    "orderName": string,
    "roomNo": string,
    "orderId": number,
    "useDate": string,
    "point": number,
    "pointType": boolean,
    "pax": number,
    "birthDate": string,
    "customerName": string,
    "customerEmail": string,
    "customerPhone": string,
    "hotelReservationId": number
  }) {
    return this._api.post(Config.Api.MobexOrders_FreeService, data);
  }
}
