import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class GgNetworkService {
  constructor() { }

  // Bağlantı durumu değiştiğinde abone olunur
  isConnectInternet() {
    return Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed:', status);
    });
  }

  // Bağlantı kesildiğinde abone olunur
  isDisconnect() {
    return Network.addListener('networkStatusChange', (status) => {
      if (status.connected === false) {
        console.log('Disconnected from network');
      }
    });
  }
  // Bağlantı durumu değişikliklerini dinler
  onchange() {
    return Network.addListener('networkStatusChange', (status) => {
      console.log('Connection type:', status);
    });
  }

  // İnternet bağlantı tipi kontrol edilir
  async connectionType() {
    const status = await Network.getStatus();
    if (status.connected) {
      return true;
    } else {
      return false;
    }
  }

  // İnternet bağlantı tipini promise olarak kontrol etme
  async connectionType2() {
    const status = await Network.getStatus();
    if (status.connected) {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }
}
