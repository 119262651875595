import { Component, OnInit, ViewChild } from '@angular/core';
import { IonItemSliding, IonList, ModalController } from '@ionic/angular';
import { Config } from '../../app.const';
import { Order } from 'src/app/interfaces/Order';
import { ApiService } from 'src/app/services/api/api.service';
import { GgEventService } from 'src/app/services/GgEvent/gg-event.service';
import { GgNotifyService } from 'src/app/services/GgNotify/gg-notify.service';
import { GgPageService } from 'src/app/services/ggPage/gg-page.service';
import { GgStorageService } from 'src/app/services/GgStorage/gg-storage.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart/shopping-cart.service';
import * as moment from 'moment';
import { OpenPageService } from 'src/app/services/open-page/open-page.service';

@Component({
  selector: 'app-spa-basket',
  templateUrl: './spa-basket.page.html',
  styleUrls: ['./spa-basket.page.scss'],
})
export class SpaBasketPage implements OnInit {
  data: any = [];
  prices = [];
  piece;
  page: GgPageService;
  basketPageLangContent: any;
  currency = '';
  buttonCurrenct;
  @ViewChild('ionSPList') ionSPList: IonList;
  @ViewChild('ionSPSliding') ionSPSliding: IonItemSliding;
  dateList: Array<string> = [];
  constructor(
    private _modalController: ModalController,
    private _page: GgPageService,
    private _events: GgEventService,
    private _storage: GgStorageService,
    private _shoppingCartService: ShoppingCartService,
    private _apiService: ApiService,
    private _notify: GgNotifyService,
    private _openPage: OpenPageService
  ) {
    this.page = _page;
    this._events.subscribe(Config.Keys.shoppingCart_refresh, (data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this._apiService.get(`${Config.Api.Baskets}`).subscribe(
      (response) => {
        this.basketPageLangContent = response.pageLanguageContent;
        this.piece = this.basketPageLangContent[this.page.shortLangCode].Piece;
      },
      (error) => {
        console.log(error);
      }
    );

    this._shoppingCartService.spaBasketList().then(
      (list) => {
        this.data = list;
        for (let i = 0; i < this.data.length; i++) {
          this.dateList.push(
            this.data[i].selectedDate != null &&
              this.data[i].selectedDate == undefined
              ? null
              : this.data[i].selectedDate
          );
          this.prices[i] = this.data[i].pax * this.data[i].price;
        }
        if (this.data.length > 0) {
          this.currency = this.getCurrency(0);
        }

        this.openCloseStartFunction();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  checkSubmitStatus: boolean = false;
  submitBasket() {
    if (this.page.isSignIn(null)) {
      let dOrder: Array<any> = [];
      for (let i = 0; i <= this.data.length - 1; i++) {
        let order = {
          customerId: this.page.userData.mobexCustomersId,
          orderId: this.data[i].id,
          orderName:
            this.data[i].languageContent[this.page.shortLangCode].title,
          pax: 1,
          point: this.data[i].point,
          pointType: this.data[i].pointType,
          price: this.data[i].price,
          useDate:
            this.dateList[i] != null
              ? moment(this.dateList[i], 'DD.MM.YYYY').format(
                'YYYY-MM-DDThh:mm:ss.msZ'
              )
              : moment().format('YYYY-MM-DDThh:mm:ss.msZ'),
          priceCurrencyTypeId: this.data[i].priceCurrencyTypeId,
          employeeId:
            this.data[i].employeeId == null &&
              this.data[i].employeeId == undefined
              ? null
              : this.data[i].employeeId,
          secondOwnerId:
            this.data[i].secondOwnerId != undefined &&
              this.data[i].secondOwnerId != null
              ? this.data[i].secondOwnerId
              : 0,
        };
        if (order.useDate != null || this.checkSubmitStatus) {
          dOrder.push(order);
          if (this.data.length - 1 == i) {
            this.submitData(dOrder);
            this.checkSubmitStatus = false;
          }
        } else {
          this._notify.simpleAlertShow(
            null,
            this.basketPageLangContent[this.page.shortLangCode].notEntered,
            this.basketPageLangContent[this.page.shortLangCode].Ok
          );
          this.checkSubmitStatus = true;
          break;
        }
      }
    }
  }

  submitData(data) {
    this._notify.show().then(() => {
      this._apiService
        .post(Config.Api.MobexOrders_personalCare, data, null, true)
        .subscribe(
          (postResponse) => {
            this._notify.hide();
            this._shoppingCartService.spaDeleteAll();
          },
          (err) => {
            this._notify.hide();
          }
        );
    });
  }

  getCurrency(index) {
    let currencyType = this.data[index].priceCurrencyTypeId;
    let currencys = ['₺', '₽', '€', '$', '£', 'Kč', 'PP'];
    return currencys[currencyType];
  }

  getTotalPrice() {
    var totalPrice = 0;
    if (this.data.length <= 0) return totalPrice;
    for (var i = 0; i < this.data.length; i++)
      totalPrice += this.data[i].price * this.data[i].pax;
    return totalPrice;
  }

  remove(_index) {
    let item = this.data[_index];
    this._notify.confirm(
      '',
      `${this.basketPageLangContent[this.page.shortLangCode].AreYouSure} `,
      `${this.basketPageLangContent[this.page.shortLangCode].Cancel}`,
      () => { },
      `${this.basketPageLangContent[this.page.shortLangCode].Ok}`,
      () => {
        this._shoppingCartService
          .spaDelete(this.data[_index], _index)
          .then(() => {
            this._shoppingCartService.spaBasketList().then((list: any) => {
              if (list.length == 0) {
                this.close();
              }
            });
          });
      }
    );
  }

  increase(_index) {
    this.data[_index].pax += 1;
    this.updateLocalData();
  }

  decrease(_index) {
    if (this.data[_index].pax > 1) this.data[_index].pax -= 1;
    this.updateLocalData();
  }

  updateLocalData() {
    this._storage.set(Config.Keys.spaShoppingCart, this.data);
    this._events.publish(Config.Keys.spaShoppingCart_refresh, this.data);
  }

  close() {
    this._modalController.dismiss();
  }

  closeAllSlidings() {
    this.ionSPList.closeSlidingItems();
  }

  openCloseStartFunction() {
    setTimeout(() => {
      this.ionSPSliding.open('start').then(() => {
        setTimeout(() => {
          this.ionSPSliding.open('end').then(() => {
            setTimeout(() => {
              this.ionSPSliding.closeOpened();
            }, 500);
          });
        }, 500);
      });
    }, 1000);
  }

  editItem(item, index) {
    this._notify.customInputAlert(
      this.basketPageLangContent[this.page.shortLangCode].dateSelectTitle,
      this.basketPageLangContent[this.page.shortLangCode].dateSelect,
      this.basketPageLangContent[this.page.shortLangCode].Cancel,
      () => { },
      this.basketPageLangContent[this.page.shortLangCode].Ok,
      (date) => {
        this.dateList[index] = moment(date.selectedDate, 'YYYY-MM-DD').format(
          'DD.MM.YYYY'
        );
      },
      [
        {
          type: 'date',
          name: 'selectedDate',
          label:
            this.basketPageLangContent[this.page.shortLangCode].dateSelectDate,
          value: moment().format('YYYY-MM-DD'),
          min: moment().format('YYYY-MM-DD'),
          max: moment().add('year', 90).format('YYYY-MM-DD'),
        },
      ]
    );
  }

  deleteItem(item, index) {
    this.remove(index);
  }

  returnForContent() {
    if (Config.Api.homepageToolbar) {
      return true;
    } else {
      return false;
    }
  }

  returnForFab() {
    if (Config.Api.homepageToolbar) {
      return '90px';
    } else {
      return '10px';
    }
  }
}
