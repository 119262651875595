import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'trusted'
})
export class TrustedPipe implements PipeTransform {

  constructor(
    private _dom: DomSanitizer,
  ) { }
  transform(value: string, type: string) {
    switch (type) {
      case 'url':
        return this._dom.bypassSecurityTrustResourceUrl(value);
      default:
        return this._dom.bypassSecurityTrustHtml(value);
    }
  }
}
